import axiosClient from "../axiosClient";

export const userApi = {
  async getInfoAccount() {
    const url = "/user";
    return axiosClient.get(url);
  },

  async changePassword(data: any) {
    const url = "user/change-password";
    return axiosClient.post(url, data);
  },

  // async updateInfoAccount(data: any) {
  //   const url = "/user";
  //   return axiosClient.post(url, data);
  // },

  async updateInfoAccount(data: any) {
    const url = "/user";
    return axiosClient.post(url, data.data, { headers: { "Content-Type": "multipart/form-data" } });
  },

  async getCalendar() {
    const url = "/service/schedule";
    return axiosClient.get(url);
  },

  async postReview(data: any) {
    const url = "/service/rate_tasker";
    return axiosClient.post(url, data);
  },

  async getPromotion() {
    const url = "/news/news";
    return axiosClient.get(url);
  },
  async additionUserData(data: object) {
    const url = "/user/addition-user-data";
    return axiosClient.post(url, data);
  },
  async getJobsUser() {
    const url = "/service/jobs";
    return axiosClient.get(url);
  },
  async getFamilyUser() {
    const url = "/family/my-family";
    return axiosClient.get(url);
  },
  async createFamilyUser(data: any) {
    const url = "/family/create-family";
    return axiosClient.post(url, data, { headers: { "Content-Type": "multipart/form-data" } });
  },
  async updateFamilyUser(data: any) {
    const url = "/family/update-family";
    return axiosClient.post(url, data, { headers: { "Content-Type": "multipart/form-data" } });
  },
  async getListScheduleUser() {
    const url = "request-schedule";
    return axiosClient.get(url);
  },

  async getScheduleUser() {
    const url = "/service/schedule";
    return axiosClient.get(url);
  },

  async getScheduleDetailUser(id: string) {
    const url = `/service/schedule-detail?id=${id}`;
    return axiosClient.get(url);
  },

  async getNotifyUser() {
    const url = "/service/notifications";
    return axiosClient.get(url);
  },

  async getNotifyDetailUser(id: string) {
    const url = `/service/notification-detail?id=${id}`;
    return axiosClient.get(url);
  },

  async getStatisticsUser() {
    const url = "/request-statistics";
    return axiosClient.get(url);
  },

  async getTutorialsUser(id: string) {
    const url = "/tutorials";
    return axiosClient.get(url);
  },

  async getAllVoucherUser() {
    const url = "/news/news";
    return axiosClient.get(url);
  },

  async getVoucherByIdUser(id: string) {
    const url = `/news/getnews?id=${id}&device=web`;
    return axiosClient.get(url);
  },

  async getTaskerDocument(id: number) {
    const url = "/service/show-tasker-documents";
    return axiosClient.get(url, { params: { tasker_id: id } });
  },
};
