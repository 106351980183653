import React, { useEffect, useState } from "react";

import HeaderSevice from "../../../components/HeaderService";
import icon_user from "../../../assets/icons/icon_user.png";

import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getFamilyUser } from "../../../redux/Slices/userSlice";
// import overLoader from "../../../components/overLoader";
import { Link } from "react-router-dom";
import local, { getLocalStorage } from "../../../helper/localStorage";
import { useTranslation } from "react-i18next";
import paths from "../../../config";

export const SelectUser = () => {
  //   const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const FamilyUser = useAppSelector((state) => state.user.familyUser);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const data_booking = local.StorageGetSecure("data_booking");
  const { t, i18n } = useTranslation(["booking"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  useEffect(() => {
    dispatch(getFamilyUser());
  }, [dispatch]);

  const handleSelectUser = (item: any) => {
    setSelectedUserId(item.fm_id);
    local.StorageSetSecure("data_booking", { ...data_booking, fm_id: item.fm_id });
  };

  const RenderDataFamilyUser =
    FamilyUser?.datas?.length > 0 &&
    FamilyUser?.datas.map((item: any, index: number) => (
      <label className="relative-item" key={index} onClick={() => handleSelectUser(item)}>
        <div className="relative-item-wrapper">
          <div className="relative-item-avt">
            <img src={item.avatar ? item.avatar : icon_user} alt="" />
          </div>
          <div className="relative-item-info">
            <div className="relative-item-name">{item.name}</div>
          </div>
        </div>
        <div className="relative-item-select">
          <input
            type="radio"
            id={item.fm_id}
            className="relative-item-check"
            checked={selectedUserId === item.fm_id || data_booking?.fm_id === item.fm_id}
          />
        </div>
      </label>
    ));
  //   : navigate("/user/family_infor/add");

  return (
    <div className="wrapper select-user">
      <HeaderSevice text={t("chon_nguoi_su_dung_dich_vu")} />
      <div className="relative content-container has-bottom-button">
        <div className="relative-list">
          <div className="relative-add">
            {/* <Link to="/user/family_infor/add" className="relative-add-button">
              {t("them_nguoi_than")}
              </Link> */}
            <Link to={paths.addFamilyInfoUser} className="relative-add-button">
              {t("them_nguoi_than")}
            </Link>
          </div>
          {RenderDataFamilyUser}
        </div>

        {/* <div className="amount">
          <div className="amount-item">
            <span className="amount-text"> Giá tiền</span>
            <span className="amount-value">200.000đ</span>
          </div>
          <div className="amount-item">
            <span className="amount-text">Giảm giá</span>
            <span className="amount-value">-20.000đ</span>
          </div>
          <div className="amount-item">
            <span className="amount-text">Tổng tiền</span>
            <span className="amount-value">180.000đ</span>
          </div>
        </div> */}
      </div>
      <div className="booking-button-wrapper">
        {/* <Link
          to="/user/booking/"
          //   onClick={() => navigate("/user/booking/payment", { state: { user_id: selectedUserId } })}
          className="booking-button primary"
        >
          {t("tiep_theo")}
        </Link> */}
        <Link
          to={paths.booking}
          //   onClick={() => navigate("/user/booking/payment", { state: { user_id: selectedUserId } })}
          className="booking-button primary"
        >
          {t("tiep_theo")}
        </Link>
      </div>
    </div>
  );
};
