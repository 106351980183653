import { useNavigate } from "react-router-dom";
import HeaderSevice from "../../../components/HeaderService";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getPathByIndex } from "../../../utils/func";
import { useEffect } from "react";
import { getServiceDetail, setTemplateType } from "../../../redux/Slices/serviceSlice";
import { useTranslation } from "react-i18next";
import paths from "../../../config";

export const ServiceDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const id = getPathByIndex(2)?.toString();
  const serviceData = useAppSelector((state) => state.service.serviceDetail);
  const { t } = useTranslation(["service"]);

  useEffect(() => {
    if (id) {
      dispatch(getServiceDetail(id));
    }
  }, [navigate, id, dispatch]);

  const handleClickBookingService = () => {
    dispatch(setTemplateType(serviceData?.template_type));
    // navigate("/user/booking/map");
    navigate(paths.bookingMap);
  };

  return (
    <div className="wrapper service-info">
      <HeaderSevice text={serviceData ? serviceData.name : ""} />
      <div className="content content-container has-bottom-button">
        <div className="banner">
          <img src={serviceData ? serviceData.image : ""} alt="" />
        </div>
        <div className="description">
          <div className="description-wrapper">
            {/* <span className="content-title">Giới thiệu dịch vụ</span> */}
            <span className="content-title">{t("gioi_thieu_dich_vu")}</span>
            {/* <div className="content-text"> */}
            <p className="content-text">{serviceData ? serviceData.introduce : ""}</p>
            {/* </div> */}
          </div>
          <div className="description-wrapper">
            {/* <span className="content-title">Nhân viên của Bluecare sẽ thực hiện những công việc gì?</span> */}
            <span className="content-title">{t("nhung_cong_viec_chung_toi_lam")}</span>
            <p className="content-text">{serviceData ? serviceData.working : ""}</p>
          </div>
          <div className="description-wrapper">
            {/* <span className="content-title">Các công việc không bao gồm thực hiện</span> */}
            <span className="content-title">{t("cac_cong_viec_khong_bao_gom_thuc_hien")}</span>
            <p className="content-text">{serviceData ? serviceData.not_working : ""}</p>
          </div>
          <div className="description-wrapper">
            {/* <span className="content-title">Quy trình thực hiện</span> */}
            <span className="content-title">{t("quy_trinh_thuc_hien")}</span>
            <div className="content-carousel">
              <div className="carousel">
                {serviceData?.procedure?.length > 0
                  ? serviceData.procedure.map((item: any, index: number) => (
                      <div className="carousel-item" key={index}>
                        <span className="carousel-item-title">{`${t("buoc")} ${index + 1}`}</span>
                        <span className="carousel-item-text">{item}</span>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="booking-button-wrapper">
        <button onClick={handleClickBookingService} className="booking-button primary">
          {/* Xem báo giá dịch vụ */}
          {t("xem_bao_gia_dich_vu")}
        </button>
      </div>
    </div>
  );
};
