import { useLocation } from "react-router-dom";
import HeaderSevice from "../../../components/HeaderService";
import { formatPrice } from "../../../utils/func";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import image_user_avatar from "../../../assets/images/image_user.png";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";

export const CalendarPartnerDetail = () => {
  const { state } = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const jobDetail = state;
  const { t, i18n } = useTranslation(["calendar"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  return (
    <div className="wrapper calendar-detail-partner">
      <HeaderSevice text={t("chi_tiet")} />
      <div className="calendar-detail">
        <div className="booking-info">
          <p className="booking-info-title">{t("thong_tin_dat_lich")}</p>
          <table className="booking-info-detail">
            <tbody>
              <tr>
                <td className="booking-info-key">{t("ma_dich_vu")}</td>
                <td className="booking-info-value">{jobDetail?.id}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("dich_vu")}</td>
                <td className="booking-info-value">{jobDetail?.service}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("ngay_bat_dau")}</td>
                <td className="booking-info-value">{jobDetail?.day_working}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("gio_bat_dau")}</td>
                <td className="booking-info-value">{jobDetail?.time_start}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="user-info">
          <div className="user-info-name">
            <span className="title">{t("nguoi_dat_dich_vu")}</span>
            <span className="content">{jobDetail?.user?.name}</span>
          </div>
          <div className="user-info-phone">
            <span className="title">{t("so_dien_thoai")}</span>
            <a href={`tel:${jobDetail?.user?.telephone}`} className="content">
              {jobDetail?.user?.telephone}
            </a>
          </div>
          {jobDetail?.tasker?.id !== 0 && (
            <button
              onClick={() => {
                setOpen(true);
                // getInfoUser(jobDetail?.tasker?.id);
              }}
              className="user-info-detail primary"
            >
              {t("xem_chi_tiet")}
            </button>
          )}
        </div>
        <div className="payment-info">
          <table className="payment-info-detail">
            <tbody>
              <tr>
                <td className="payment-info-key">{t("gia_dich_vu")}</td>
                <td className="payment-info-value price">
                  {/* {jobDetail?.price} */}
                  {formatPrice(Number(jobDetail?.price))}
                </td>
              </tr>
              <tr>
                <td className="payment-info-key">{t("phuong_thuc_thanh_toan")}</td>
                <td className="payment-info-value">{jobDetail?.payment_method_title}</td>
              </tr>
              <tr>
                <td className="payment-info-key">{t("tinh_trang")}</td>
                <td className="payment-info-value">{jobDetail?.paid_title}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        title={t("thong_tin_nguoi_dat_dich_vu")}
        centered
        open={open}
        onCancel={() => setOpen(!open)}
        footer={null}
        className="partner-info-modal"
      >
        <div className="partner-info-modal-item-image">
          <img src={jobDetail?.user?.avatar !== "" ? jobDetail?.user?.avatar : image_user_avatar} alt="" />
        </div>
        <div className="partner-info-modal-item">
          <span className="partner-info-modal-item-title">{t("ho_ten")}</span>
          <span className="partner-info-modal-item-content">{jobDetail?.user?.name}</span>
        </div>
        <div className="partner-info-modal-item">
          <span className="partner-info-modal-item-title">{t("so_dien_thoai")}</span>
          <a href={`tel:${jobDetail?.user?.telephone}`} className="partner-info-modal-item-content">
            {jobDetail?.user?.telephone}
          </a>
        </div>
        <div className="partner-info-modal-item">
          <span className="partner-info-modal-item-title">{t("danh_gia_trung_binh")}</span>
          <div className="partner-info-modal-item-content rate">
            <span className="text">{jobDetail?.user?.avg_rate}</span>
            <span className="star">★</span>
          </div>
        </div>
      </Modal>
    </div>
  );
};
