import { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderSevice from "../../../components/HeaderService";
import icon_wallet_1 from "../../../assets/icons/icon_wallet_1.png";
// import overLoader from "../../../components/overLoader";
// import icon_back from "../../../assets/icons/icon_back.png";

import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getSalaryDataPartner } from "../../../redux/Slices/partnerSlice";
import paths from "../../../config";
import { formatPrice } from "../../../utils/func";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";

export const Wallet = () => {
  //   const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const paymentData = useAppSelector((state) => state.partner.paymentData);
  const { t, i18n } = useTranslation(["wallet", "home"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);
  useEffect(() => {
    dispatch(getSalaryDataPartner());
  }, [dispatch]);

  return (
    <div className="wrapper wallet">
      <HeaderSevice text={t("home:vi_tien")} />
      <div className="wallet">
        <div className="wallet-info">
          <div className="wallet-info-icon">
            <img src={icon_wallet_1} alt="" />
          </div>
          <h3 className="wallet-info-text">{paymentData?.salary?.money && formatPrice(+paymentData?.salary?.money)}</h3>
        </div>
        <div className="wallet-setting">
          <Link to={paths.bankInfoPartner} className="wallet-setting-item">
            {t("tai_khoan_nhan_tien")}
          </Link>
          <Link to={paths.ruleWalletPartner} className="wallet-setting-item">
            {t("quy_dinh_nhan_tien")}
          </Link>
        </div>
      </div>
    </div>
  );
};
