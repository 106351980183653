import { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderSevice from "../../../components/HeaderService";
import LoadingParagraph from "../../../components/skeleton";
// import overLoad from "../../../components/overLoader";

import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getInfoTasker, getTitlePartner } from "../../../redux/Slices/partnerSlice";
import paths from "../../../config";
import { TitleItem } from "../../../types/common";
import image_logo_full from "../../../assets/images/image_logo_full.png";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";

export const RegisterService = () => {
  const dispatch = useAppDispatch();
  const titleData = useAppSelector((state) => state.partner.titleData?.datas);
  const taskerData = useAppSelector((state) => state.partner.userInfor);
  const loadingTitle = useAppSelector((state) => state.partner.loadingTitle);
  const { t, i18n } = useTranslation(["home"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  useEffect(() => {
    dispatch(getTitlePartner());
    dispatch(getInfoTasker());
  }, [dispatch]);

  const renderServiceItem = () => {
    return (
      titleData?.length > 0 &&
      titleData.map((item: TitleItem) => (
        <Link
          // to={`${item.id === taskerData?.tasker ? paths.registerServiceDetailPartner : ""}`}
          to={
            taskerData?.registed_service === 0
              ? paths.registerServiceDetailPartner
              : item.id === taskerData?.tasker
              ? paths.registerServiceDetailPartner
              : ""
          }
          state={{ tasker_type: item.id, tasker_name: item.tasker }}
          // className={`register-speciality-item ${item.id === taskerData?.tasker ? "" : "disable"}`}
          className={`register-speciality-item${
            taskerData?.registed_service === 0 ? "" : item.id === taskerData?.tasker ? "" : " disable"
          }`}
          key={item.id}
        >
          <div className="register-speciality-item--right">
            <div className="register-speciality-item-image">
              <img src={item?.image !== null ? item.image : image_logo_full} alt="" />
            </div>
            <span className="register-speciality-item-text">{item.tasker}</span>
          </div>
        </Link>
      ))
    );
  };

  return (
    <div className="wrapper register-speciality">
      <HeaderSevice text={t("dang_ki_vi_tri_chuyen_mon")} />
      <div className="register-speciality content-container">
        <h1 className="register-speciality-title">{t("dang_ki_vi_tri_chuyen_mon")}</h1>
        <div className="register-speciality-list">{loadingTitle ? <LoadingParagraph /> : renderServiceItem()}</div>
      </div>
    </div>
  );
};
