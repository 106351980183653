import { useEffect, useState } from "react";
import { notification } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import icon_old_person from "../../../assets/icons/icon_old_person.png";
import HeaderSevice from "../../../components/HeaderService";
import LoadingParagraph from "../../../components/skeleton";
import overLoader from "../../../components/overLoader";

import {
  getInfoTasker,
  getAllCategoriesToRegServicePartner,
  regServicePartner,
} from "../../../redux/Slices/partnerSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import paths from "../../../config";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";

export const RegisterServiceDetail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //   const taskerType = useAppSelector((state) => state.partner.userInfor?.tasker);
  const categoriesDataToRegService = useAppSelector((state) => state.partner.categoriesDataToRegService?.datas);
  const loadingCategoryToRegService = useAppSelector((state) => state.partner.loadingCategoryToRegService);
  const [api, showPopup] = notification.useNotification();
  const [categories, setCategories] = useState<string[]>([]);
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const { state } = useLocation();
  const { t, i18n } = useTranslation(["home"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  useEffect(() => {
    dispatch(getInfoTasker());
    dispatch(getAllCategoriesToRegServicePartner());
    state === null && navigate(paths.registerServicePartner);
  }, [dispatch, state, navigate]);

  useEffect(() => {
    if (categoriesDataToRegService) {
      const checkedItem = categoriesDataToRegService
        //filter checked item
        .filter((category: any) => category.checked === 1)
        // get id every item had checked
        .map((category: any) => category.id);
      setCategories([...checkedItem]);
    }
  }, [categoriesDataToRegService]);

  const handleRegister = () => {
    setStatusLoader(true);
    const data = {
      categories: [...categories],
      //   tasker_type: taskerType?.toString(),
      tasker_type: `${state?.tasker_type}`,
    };
    try {
      dispatch(regServicePartner(data)).then((resultAction: any) => {
        const payload = resultAction.payload as { message: string };
        if (regServicePartner.fulfilled.match(resultAction)) {
          alertSuccess(payload.message || "Chọn đăng kí dịch vụ thành công !");
          setStatusLoader(false);
          setTimeout(() => {
            navigate(paths.registerSubServiceDetailPartner, { state: { tasker_name: state?.tasker_name } });
          }, 1500);
        } else if (regServicePartner.rejected.match(resultAction)) {
          setStatusLoader(false);
          alertFail(payload.message || "Chọn đăng kí dịch vụ thất bại, vui lòng thử lại!");
        }
      });
    } catch (error) {
      setStatusLoader(false);
      console.error(error);
    }
  };

  const handleCheckboxChange = (item: any) => {
    if (categories.includes(item.id)) {
      setCategories(categories.filter((categoryId) => categoryId !== item.id));
    } else {
      setCategories([...categories, item.id]);
    }
  };

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 2,
    });
  };

  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  const renderListCategoriesDataToRegService = () => {
    return (
      categoriesDataToRegService?.length > 0 &&
      categoriesDataToRegService.map((item: any, index: number) => (
        <div className="select-service-item" key={index}>
          <input
            type="checkbox"
            id={`service${index}`}
            className="select-service-item-checkbox"
            name={`service${index}`}
            value={`service${index}`}
            checked={categories.includes(item.id)}
            onChange={() => handleCheckboxChange(item)}
          />
          <label htmlFor={`service${index}`} className="select-service-item-text">
            {item.name}
          </label>
        </div>
      ))
    );
  };

  return (
    <div className="wrapper register-service">
      {showPopup}
      {statusLoader && overLoader()}
      <HeaderSevice text={t("dang_ki_vi_tri_chuyen_mon")} />
      <div className="register-service">
        <div className="speciality-selected">
          <div className="speciality-selected-icon">
            <img src={icon_old_person} alt="" />
          </div>
          <span className="speciality-selected-text">
            {state?.tasker_name}
            {/* {taskerType === 0
              ? state?.tasker_type === 1
                ? "Bác sĩ"
                : state?.tasker_type === 2
                ? "Điều dưỡng viên"
                : state?.tasker_type === 3
                ? "Kỹ thuật viên"
                : state?.tasker_type === 4
                ? "Chuyên viên chăm sóc"
                : ""
              : taskerType === 1
              ? "Bác sĩ"
              : taskerType === 2
              ? "Điều dưỡng viên"
              : taskerType === 3
              ? "Kỹ thuật viên"
              : taskerType === 4
              ? "Chuyên viên chăm sóc"
              : ""} */}
          </span>
        </div>
        <div className="requirement-degree">
          <h2 className="requirement-degree-title">{t("yeu_cau_bang_cap_kinh_nghiem")}</h2>
          <ul className="requirement-degree-list">
            <li>{t("bang_trung_cap_tro_len")}</li>
            <li>{t("co_kinh_nghiem_toi_thieu_6_thang")}</li>
          </ul>
        </div>
        <div className="select-service">
          <h2 className="select-service-title">{t("chon_dich_vu_ban_co_the_thuc_hien")}</h2>
          <div className="select-service-list">
            {loadingCategoryToRegService ? <LoadingParagraph /> : renderListCategoriesDataToRegService()}
          </div>
        </div>
      </div>
      <div className="register-button-wrapper">
        <button className="register-button primary" onClick={handleRegister}>
          {t("dang_ki")}
        </button>
      </div>
    </div>
  );
};
