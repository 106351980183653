import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import ReactDOM from "react-dom/client";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_OAUTH_CLIENT, GOOGLE_OAUTH_LOCAL } from "./utils/auth/index";

import { store } from "./redux/store";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const domainName = window.location.hostname;
const clientID = domainName === "localhost" ? GOOGLE_OAUTH_LOCAL : GOOGLE_OAUTH_CLIENT;
root.render(
  <React.StrictMode>
    <ConfigProvider theme={{ token: { fontFamily: '"MyriadPro-Light", sans-serif' } }}>
      <Provider store={store}>
        <Router>
          <GoogleOAuthProvider clientId={clientID}>
            <App />
          </GoogleOAuthProvider>
        </Router>
      </Provider>
    </ConfigProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
