import { useEffect } from "react";
import HeaderSevice from "../../../components/HeaderService";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getScheduleStatistics } from "../../../redux/Slices/serviceSlice";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";
import overLoader from "../../../components/overLoader";
import paths from "../../../config";

export const StatisticPartner = () => {
  const dispatch = useAppDispatch();
  const scheduleStatistics = useAppSelector((state) => state.service.scheduleStatistics);
  const loading = useAppSelector((state) => state.service.loading);
  const { t, i18n } = useTranslation(["home"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  useEffect(() => {
    dispatch(getScheduleStatistics());
  }, [dispatch]);
  return (
    <div className="wrapper transaction-statistic-partner">
      {loading && overLoader()}
      <HeaderSevice text={t("thong_ke_giao_dich")} />
      <div className="transaction-statistic">
        <h1 className="transaction-statistic-title">{t("thong_ke_giao_dich")}</h1>
        <div className="transaction-statistic-item">
          <Link
            //   to="/partner/calendar"
            to={paths.calendarPartner}
            className="transaction-statistic-info"
          >
            <p className="transaction-statistic-text">{t("toan_bo_viec")}</p>
            <p className="transaction-statistic-number">{scheduleStatistics?.count_total_schedules}</p>
          </Link>
          <Link
            //   to="/partner/calendar"
            to={paths.calendarPartner}
            className="transaction-statistic-info"
          >
            <p className="transaction-statistic-text">{t("thanh_cong")}</p>
            <p className="transaction-statistic-number">{scheduleStatistics?.count_complete_schedules}</p>
          </Link>
        </div>
        <div className="transaction-statistic-item">
          <Link
            //   to="/partner/calendar"
            to={paths.calendarPartner}
            className="transaction-statistic-info"
          >
            <p className="transaction-statistic-text">{t("dang_lam")}</p>
            <p className="transaction-statistic-number">{scheduleStatistics?.count_working_schedules}</p>
          </Link>
          <Link
            //   to="/partner/calendar"
            to={paths.calendarPartner}
            className="transaction-statistic-info"
          >
            <p className="transaction-statistic-text">{t("dang_cho")}</p>
            <p className="transaction-statistic-number">{scheduleStatistics?.count_pending_schedules}</p>
          </Link>
        </div>
      </div>
    </div>
  );
};
