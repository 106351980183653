import { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocalStorage, setLocalStorage } from "../../../helper/localStorage";

// import icon_back from "../../../assets/icons/icon_back.png";
import HeaderSevice from "../../../components/HeaderService";

export const ChangeLanguage = () => {
  const { t, i18n } = useTranslation(["setting"]);
  const languageLocal: any = getLocalStorage("language");
  const [language, setLanguage] = useState<string>("vi");
  //   const navigate = useNavigate();

  const handleLanguage = (lang: string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    setLocalStorage("language", lang);
  };

  useEffect(() => {
    i18n.changeLanguage(languageLocal || language);
    setLanguage(languageLocal || language);
  }, [i18n, languageLocal, language]);

  return (
    <div className="wrapper change-language">
      <HeaderSevice text={t("doi_ngon_ngu")} />
      <div className="language-list content-container">
        <label htmlFor="en" className="language-item">
          <span className="language-item-text">English</span>
          <input
            type="radio"
            id="en"
            name="language"
            value="en"
            className="language-item-check"
            checked={language === "en"}
            onChange={(e: any) => handleLanguage(e.target.value)}
          />
        </label>
        <label htmlFor="vi" className="language-item">
          <span className="language-item-text">Tiếng Việt</span>
          <input
            type="radio"
            id="vi"
            name="language"
            value="vi"
            className="language-item-check"
            checked={language === "vi"}
            onChange={(e: any) => handleLanguage(e.target.value)}
          />
        </label>
      </div>
    </div>
  );
};
