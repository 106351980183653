import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authApi } from "../../api/authApi";

interface ResponseState {
  AuthResponse: any;
  loadingVerify: boolean;
  loading: boolean;
  error: string | null;
  role: string;
  accessToken: string | null;
}

const initialState: ResponseState = {
  AuthResponse: null,
  loadingVerify: false,
  loading: false,
  error: null,
  role: "",
  accessToken: null,
};

export const sendOTP = createAsyncThunk("auth/sendOTP", async (_, { rejectWithValue }) => {
  try {
    const response = await authApi.sendOTP();
    return response;
  } catch (error: any) {
    return rejectWithValue({ message: error.message });
  }
});

export const verifyOTP = createAsyncThunk("auth/verifyOTP", async (data: any, { rejectWithValue }) => {
  try {
    const response = await authApi.verifyOTP(data);
    return response;
  } catch (error: any) {
    return rejectWithValue({ message: error.message });
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state: object, action) => ({ ...state, ...action.payload }),
    setRole: (state, action) => {
      state.role = action.payload;
      localStorage.setItem("role", action.payload.role);
    },
    logout: (state) => {
      state.role = "";
      state.accessToken = null;
      localStorage.removeItem("role");
      localStorage.removeItem("access_token");
    },
  },
  extraReducers: (builder) => {
    builder
      // reducer of send OTP sms
      .addCase(sendOTP.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendOTP.fulfilled, (state, action) => {
        state.loading = false;
        state.AuthResponse = action.payload;
      })
      .addCase(sendOTP.rejected, (state, action) => {
        state.loading = false;
        state.AuthResponse = action.payload;
      })

      // reuducer of verify OTP
      .addCase(verifyOTP.pending, (state) => {
        state.loadingVerify = true;
        state.error = null;
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.loadingVerify = false;
        state.AuthResponse = action.payload;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.loadingVerify = false;
        state.AuthResponse = action.payload;
      });
  },
});

export default authSlice.reducer;
export const { setAuth, setRole, logout } = authSlice.actions;
