import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Carousel } from "antd";

import { getLocalStorage } from "../../../helper/localStorage";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getCategoriesPartner, getInfoTasker } from "../../../redux/Slices/partnerSlice";
import ConfirmOTP from "../../../components/ModalConfirmOTP";
import overLoader from "../../../components/overLoader";
import paths from "../../../config";
import { CategoryHomePartner, BannerHomePartner } from "../../../types/common";

import icon_hamburger from "../../../assets/icons/icon_hamburger.png";
import icon_phone from "../../../assets/icons/icon_phone.png";
import image_logo_horizontal from "../../../assets/images/image_logo_horizontal.png";
import icon_calendar from "../../../assets/icons/icon_calendar.png";
import icon_report from "../../../assets/icons/icon_report.png";
import icon_notification from "../../../assets/icons/icon_notification.png";
import icon_statistic from "../../../assets/icons/icon_statistic.png";
import icon_wallet from "../../../assets/icons/icon_wallet.png";
import icon_user from "../../../assets/icons/icon_user.png";
import icon_setting from "../../../assets/icons/icon_setting.png";
import icon_info from "../../../assets/icons/icon_info.png";
import image_banner from "../../../assets/images/image_banner.png";
import icon_help from "../../../assets/icons/icon_help.png";
import icon_relative from "../../../assets/icons/icon_relative.png";

export const HomePartner = () => {
  //   const navigate = useNavigate();
  const { t, i18n } = useTranslation(["home"]);
  const languageLocal: any = getLocalStorage("language");
  const [showSiderBar, setShowSiderBar] = useState<boolean>(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState<boolean>(true);
  //   const [api, showPopup] = notification.useNotification();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state: any) => state.partner.userInfor);
  const loadingSendOTP = useAppSelector((state: any) => state.auth.loading);
  const loading = useAppSelector((state: any) => state.partner.loading);
  const categories = useAppSelector((state) => state.partner.categoriesHome);
  const banners = useAppSelector((state) => state.partner.bannerHome);

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
    dispatch(getInfoTasker());
    dispatch(getCategoriesPartner());
  }, [i18n, languageLocal, dispatch]);

  //   const checkValidatePhone = async () => {
  //     setIsPhoneVerified(false);
  //     try {
  //       const response: any = await dispatch(sendOTP());
  //       if (response?.payload?.status === 1) {
  //         alertSuccess(response?.payload?.message);
  //       } else if (response?.payload?.status === 0) {
  //         alertFail(response?.payload?.message);
  //       } else {
  //         alertFail(response?.payload?.message);
  //       }
  //     } catch (error: any) {
  //       alertFail(error?.payload?.message);
  //     }
  //   };

  // check cancel navigate if user verify phone number yet
  //   const handleServiceClick = () => {
  // if (userInfo?.telephone_is_real) {
  //   const data = {
  //     category_id: id,
  //     // name_category,
  //     limit: 50,
  //     client: "web",
  //   };
  //   dispatch(getScheduleByCategoryPartner(id));
  //   navigate("/partner/task", { state: { category_id: id, name_category } });
  // } else {
  // checkValidatePhone();
  // }
  //   };

  //   const alertSuccess = (message: string) => {
  //     api.success({
  //       message,
  //       placement: "topRight",
  //       duration: 2,
  //     });
  //   };

  //   const alertFail = (message: string) => {
  //     api.error({
  //       message,
  //       placement: "topRight",
  //       duration: 3,
  //     });
  //   };

  const renderedListMenuItem = () => {
    return (
      categories?.length > 0 &&
      categories?.map((item: CategoryHomePartner, index: number) =>
        userInfo?.telephone_is_real ? (
          <Link
            // to="/partner/task"
            to={paths.taskPartner}
            state={{ category_id: item.id, name_category: item.name }}
            key={index}
            className="service-item no-highlight"
          >
            <div className="service-item-icon">
              <img src={item.image} alt="" />
              <div className="service-item-count">
                <span>{item.schedules_count}</span>
              </div>
            </div>
            <span className="service-item-text">{item.name}</span>
          </Link>
        ) : (
          <div onClick={() => setIsPhoneVerified(false)} key={index} className="service-item no-highlight">
            <div className="service-item-icon">
              <img src={item.image} alt="" />
              <div className="service-item-count">
                <span>{item.schedules_count}</span>
              </div>
            </div>
            <span className="service-item-text">{item.name}</span>
          </div>
        ),
      )
    );
  };

  // menu list wrapper
  const menuListWarapperItems = [
    { link: paths.calendarPartner, img: icon_calendar, text: t("lich_cua_toi"), className: "menu-item" },
    { link: paths.reportPartner, img: icon_report, text: t("bao_cao"), className: "menu-item" },
    { link: paths.registerServicePartner, img: icon_relative, text: t("dang_ki_dich_vu"), className: "menu-item" },
    { link: paths.notifyPartner, img: icon_notification, text: t("danh_sach_thong_bao"), className: "menu-item" },
    { link: paths.statisticPartner, img: icon_statistic, text: t("thong_ke_giao_dich"), className: "menu-item" },
    { link: paths.walletPartner, img: icon_wallet, text: t("vi_tien"), className: "menu-item" },
    { link: paths.settingPartner, img: icon_setting, text: t("cai_dat"), className: "menu-item" },
    { link: paths.helpPartner, img: icon_help, text: t("tro_giup"), className: "menu-item" },
    { link: paths.aboutPartner, img: icon_info, text: t("ve_bluecare"), className: "menu-item" },
  ];

  return (
    <div className="wrapper home-partner">
      {/* {showPopup} */}
      {loading && overLoader()}
      {loadingSendOTP && overLoader()}
      <div className="header">
        <button className="header-hamburger no-highlight" onClick={() => setShowSiderBar(!showSiderBar)}>
          <div className="header-hamburger-icon">
            <img src={icon_hamburger} alt="" />
          </div>
        </button>
        <Link to={paths.homePartner} className="header-logo no-highlight">
          <img src={image_logo_horizontal} alt="" />
        </Link>
        <a href="tel:0985768181" className="header-call no-highlight">
          <img src={icon_phone} alt="" />
        </a>
      </div>
      <div className={`menu ${showSiderBar ? "active" : ""}`}>
        <Link
          // to="/partner/setting/my_account"
          to={paths.myAccountPartner}
          className="menu-account"
        >
          <div className="menu-account-image">
            <img src={userInfo?.avatar !== "" ? userInfo?.avatar : icon_user} alt="" />
          </div>
          <span className="menu-account-text">{userInfo?.name}</span>
        </Link>

        {/* banner here */}
        <div className="menu-list">
          <div className="menu-list-wrapper">
            {menuListWarapperItems.map((item, index) => (
              <Link key={index} to={item.link} className={item.className}>
                <div className="menu-item-icon">
                  <img src={item.img} alt={item.text} />
                </div>
                <span className="menu-item-text">{item.text}</span>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="banner">
        <div className="banner-list">
          <div className="banner-item">
            <Carousel autoplay autoplaySpeed={2000} infinite>
              {banners?.length > 0 ? (
                banners.map((image: BannerHomePartner, index: number) => (
                  <div key={index}>
                    <img src={image.image} alt={`Slide ${index + 1}`} className="slider-image" />
                  </div>
                ))
              ) : (
                <img src={image_banner} alt="" />
              )}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="service">
        <div className="service-list">{renderedListMenuItem()}</div>
        {/* {categories?.length > 0 ? (
          <div className="service-list">{renderedListMenuItem()}</div>
        ) : (
          <h1 className="service-no-data">Bạn chưa đăng kí dịch vụ</h1>
        )} */}
      </div>
      <div className={`overlay ${showSiderBar ? "active" : ""}`} onClick={() => setShowSiderBar(!showSiderBar)}></div>
      {/* check verify phone number */}
      {/* <div className={`overlay ${isPhoneVerified ? "active" : ""}`}>
        {isPhoneVerified ? (
          <ConfirmOTP onClose={() => setIsPhoneVerified(false)} userInfor={userInfo} role="tasker" />
        ) : null}
      </div> */}
      <div className={`overlay ${!isPhoneVerified ? "active" : ""}`}>
        <ConfirmOTP onClose={() => setIsPhoneVerified(!isPhoneVerified)} userInfor={userInfo} role="tasker" />
      </div>
    </div>
  );
};
