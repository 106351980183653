import { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderSevice from "../../../components/HeaderService";
import icon_add from "../../../assets/icons/icon_add.png";
import icon_user from "../../../assets/icons/icon_user.png";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getFamilyUser } from "../../../redux/Slices/userSlice";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";
import paths from "../../../config";
// import overLoader from "../../../components/overLoader";

export const FamilyInfo = () => {
  //   const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const FamilyUser = useAppSelector((state) => state.user.familyUser);
  const { t, i18n } = useTranslation(["family"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  //   const handleNavigate = (item: {}) => {
  //     navigate("/user/family_infor/update", { state: item });
  //   };

  const RenderDataFamilyUser =
    FamilyUser?.datas?.length > 0 &&
    FamilyUser.datas.map((item: any, index: number) => (
      <Link
        //   to="/user/family_infor/update"
        to={paths.updateFamilyInfoUser}
        state={item}
        className="relative-item"
        key={index}
      >
        <div className="relative-item-wrapper">
          <div className="relative-item-avt">
            <img src={item.avatar ? item.avatar : icon_user} alt="" />
          </div>
          <div className="relative-item-info">
            <div className="relative-item-name">{item.name}</div>
          </div>
        </div>
      </Link>
    ));
  //   : overLoader();

  useEffect(() => {
    dispatch(getFamilyUser());
  }, [dispatch]);

  return (
    <div className="wrapper select-relative">
      <HeaderSevice text={t("thong_tin_nguoi_than")} />
      <div className="relative content-container">
        <div className="relative-list">
          <div className="relative-add">
            <Link
              // to="/user/family_infor/add"
              to={paths.addFamilyInfoUser}
              className="relative-add-button"
            >
              <div className="relative-add-icon">
                <img src={icon_add} alt="" />
              </div>
              <span className="relative-add-text">{t("them_moi")}</span>
            </Link>
          </div>
          {RenderDataFamilyUser}
        </div>
      </div>
    </div>
  );
};
