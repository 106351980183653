import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { authApi } from "../../../api/authApi";
import { notification } from "antd";
// import { useTranslation } from "react-i18next";
import overLoader from "../../../components/overLoader";

import image_logo_full from "../../../assets/images/image_user.png";
import icon_back from "../../../assets/icons/icon_back.png";
import icon_phone from "../../../assets/icons/icon_phone.png";
import icon_user from "../../../assets/icons/icon_user.png";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";
import { COMPANY_PHONE } from "../../../utils/common";
import paths from "../../../config";

type SubmitForm = {
  phone: number;
  email: string;
};

export const ResetPassword = () => {
  // const { t } = useTranslation();
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [api, showPopup] = notification.useNotification();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["auth"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 2,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SubmitForm>();

  const handleForgotPassword: any = async (data: any) => {
    setStatusLoader(true);
    try {
      const resData: any = await authApi.resetPassword({
        email: data.email,
        phone: data.phone,
      });
      //   console.log("resData", resData);
      if (resData.status === 1) {
        alertSuccess(resData.message);
        setStatusLoader(false);
      } else if (resData.status === 0) {
        alertFail(resData.message);
        setStatusLoader(false);
      } else {
        alertFail(resData.message);
        setStatusLoader(false);
      }
    } catch (err: any) {
      //   console.log("err", err);
      setStatusLoader(false);
      alertFail(err.response.data.message);
      //   console.log("Register Fail: ", err);
    }
  };

  return (
    <div className="wrapper forgot-password">
      {statusLoader ? overLoader() : ""}
      {showPopup}
      <div className="header">
        <Link to="#" onClick={() => navigate(-1)}>
          <button className="header-back-button">
            <div className="header-back-icon">
              <img src={icon_back} alt="" />
            </div>
          </button>
        </Link>
        <span className="header-text">{t("quen_mat_khau")}</span>
        <a href={`tel:${COMPANY_PHONE}`} className="header-call">
          <img src={icon_phone} alt="" />
        </a>
      </div>
      <div className="forgot-password content-container">
        <div className="logo">
          <img src={image_logo_full} alt="" />
        </div>
        <div className="forgot-password-form">
          <form onSubmit={handleSubmit(handleForgotPassword)} className="login-form-main">
            <div className="forgot-password-form-item">
              <div className="forgot-password-form-icon">
                <img src={icon_user} alt="" />
              </div>
              <input
                type="text"
                className="forgot-password-form-input"
                placeholder="Email"
                {...register("email", {
                  required: t("common:yeu_cau_khong_de_trong"),
                  pattern: {
                    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                    message: t("email_khong_hop_le"),
                  },
                })}
              />
            </div>
            {errors.email && <div className="label-require">{errors.email?.message}</div>}
            <div className="forgot-password-form-item">
              <div className="forgot-password-form-icon">
                <img src={icon_phone} alt="" />
              </div>
              <input
                type="text"
                className="forgot-password-form-input"
                placeholder="Số điện thoại"
                inputMode="numeric"
                {...register("phone", {
                  required: t("common:yeu_cau_khong_de_trong"),
                  pattern: { value: /^[0-9]+$/, message: t("so_dien_thoai_khong_hop_le") },
                  minLength: { value: 10, message: t("so_dien_thoai_khong_hop_le") },
                  maxLength: { value: 10, message: t("so_dien_thoai_khong_hop_le") },
                })}
              />
            </div>
            {errors.phone && <div className="label-require">{errors.phone?.message}</div>}
            <div className="forgot-password-form-item button">
              {/* <Link to="/user/login" className="forgot-password-form-cancelbtn secondary">
                {t("huy")}
              </Link> */}
              <Link to={paths.login} className="forgot-password-form-cancelbtn secondary">
                {t("huy")}
              </Link>
              <button className="forgot-password-form-submitbtn primary">{t("xac_nhan")}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
