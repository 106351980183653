export const validateEmail = (email: string): Promise<void> => {
  if (email === "") return Promise.reject(new Error("Vui lòng điền email!"));
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (regex.test(email)) return Promise.resolve();
  else return Promise.reject(new Error("Email không hợp lệ!"));
};

export const validatePhoneNumber = (_: any, phoneNumber: string): Promise<void> => {
  const regex = /^\+?[0-9]{10}$/;
  if (!phoneNumber || regex.test(phoneNumber)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Số điện thoại không hợp lệ!"));
};

export const validatePassword = (password: string): Promise<void> => {
  if (!password || password.length < 6) return Promise.reject(new Error("Mật khẩu phải dài hơn 6 kí tự!"));
  return Promise.resolve();
};
