import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getNotifyUser } from "../../../redux/Slices/userSlice";
import HeaderSevice from "../../../components/HeaderService";
import overLoader from "../../../components/overLoader";
import { NotifyUser } from "../../../types/common";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";
import paths from "../../../config";

export const Notification = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const allNoti = useAppSelector((state) => state?.user?.notifiUser?.datas);
  const loading = useAppSelector((state) => state?.user?.loading);
  const { t, i18n } = useTranslation(["home"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  useEffect(() => {
    dispatch(getNotifyUser());
  }, [dispatch]);

  const handleNavigate = (item: NotifyUser) => {
    if (item.notification_type === 1) {
      //   navigate("/user/noti/detail", { state: item });
      navigate(paths.notificationUserDetail, { state: item });
    }
  };

  const renderNotiList = () => {
    return (
      allNoti?.length > 0 &&
      allNoti.map((item: NotifyUser, index: number) => (
        <div key={index} onClick={() => handleNavigate(item)} className="notification-item">
          <div className="notification-item-icon">
            <img src={item?.image} alt="" />
          </div>
          <div className="notification-item-detail">
            <h1 className="notification-item-title">{item.title}</h1>
            <p className="notification-item-description">{item.description}</p>
            <span className="notification-item-time">{item.created_at}</span>
          </div>
        </div>
      ))
    );
  };

  return (
    <div className="wrapper notification">
      {loading && overLoader()}
      <HeaderSevice text={t("danh_sach_thong_bao")} />
      <div className="notification-list content-container">
        {renderNotiList()}
        {/* <a href="new_shift_notification.html" className="notification-item">
          <div className="notification-item-icon">
            <img src="../../assets/icons/icon_bell.png" alt="" />
          </div>
          <div className="notification-item-detail">
            <h1 className="notification-item-title">
              Nguyễn Văn A vừa đặt thêm công việc mới. Bạn có thể nhận ngay bây giờ
            </h1>
            <p className="notification-item-description">
              Nguyễn Văn A vừa đặt thêm công việc mới. Bạn có thể nhận ngay bây giờ
            </p>
            <span className="notification-item-time">12:00 - 16/09/2018</span>
          </div>
        </a>
        <a href="https://blog.bluecare.vn/dung-dich-ve-sinh-cho-ba-bau-loai-nao-tot/" className="notification-item">
          <div className="notification-item-icon">
            <img src="../../assets/images/image_logo.png" alt="" />
          </div>
          <div className="notification-item-detail">
            <h1 className="notification-item-title">Dung dịch vệ sinh cho bà bầu loại nào tốt</h1>
            <p className="notification-item-description">
              Lựa chọn dung dịch vệ sinh cho phụ nữ mang thai là một vấn đề quan trọng để đảm bảo an toàn cho cả mẹ và
              thai nhi. Dưới đây Bluecare xin chia sẻ bài viết “Dung dịch vệ sinh cho bà bầu loại nào tốt” với những
              hướng dẫn và lời khuyên về việc lựa chọn dung dịch vệ sinh cho phụ nữ mang thai, các bạn cùng tham khảo để
              đảm bảo cho mình một thai kỳ viên mãn nhé.
            </p>
            <span className="notification-item-time">16:50 - 27/07/2023</span>
          </div>
        </a> */}
      </div>
    </div>
  );
};

export default Notification;
