import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { partnerApi } from "../../api/partnerApi";
import { DataAccount, FormAddBank, PaymentData, CategoryHomePartner, BannerHomePartner } from "../../types/common";

interface UserState {
  userInfor: DataAccount | null;
  error: string | null;
  reportData: any;
  paymentData: PaymentData | null;
  bankInfor: any;
  titleData: any;
  subCategoryData: any;
  categoriesDataToRegService: any;
  categoriesHome: CategoryHomePartner[] | [];
  bannerHome: BannerHomePartner[] | [];
  scheduleByCategoryId: any;
  allSchedule: any;
  responseTakeJob: any;
  loading: boolean;
  loadingTakeJob: boolean;
  loadingScheduleByCategoryId: boolean;
  loadingAdditionUserData: boolean;
  loadingAddBank: boolean;
  loadingTitle: boolean;
  loadingCategoryToRegService: boolean;
  loadingSubCategoriesToRegService: boolean;
  loadingRegServices: boolean;
}

const initialState: UserState = {
  userInfor: null,
  error: null,
  reportData: null,
  paymentData: null,
  bankInfor: null,
  titleData: null,
  subCategoryData: null,
  categoriesDataToRegService: null,
  categoriesHome: [],
  bannerHome: [],
  scheduleByCategoryId: null,
  allSchedule: null,
  responseTakeJob: null,
  loading: false,
  loadingTakeJob: false,
  loadingScheduleByCategoryId: false,
  loadingAdditionUserData: false,
  loadingAddBank: false,
  loadingTitle: false,
  loadingCategoryToRegService: false,
  loadingSubCategoriesToRegService: false,
  loadingRegServices: false,
};

export const getInfoTasker = createAsyncThunk("partner/getInfoTasker", async (_, { rejectWithValue }) => {
  try {
    const response = await partnerApi.getInforPartner();
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

// getRevenueStatisticsPartner
export const getRevenueStatisticsPartner = createAsyncThunk(
  "partner/getRevenueStatisticsPartner",
  async (_, { rejectWithValue }) => {
    try {
      const response = await partnerApi.getRevenueStatisticsPartner();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

// getSalaryDataPartner
export const getSalaryDataPartner = createAsyncThunk("partner/getSalaryDataPartner", async (_, { rejectWithValue }) => {
  try {
    const response = await partnerApi.getSalaryDataPartner();
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

// addBankPartner
export const addBankPartner = createAsyncThunk(
  "partner/addBankPartner",
  async (data: FormAddBank, { rejectWithValue }) => {
    try {
      const response = await partnerApi.addBankPartner(data);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

//getTitlePartner
export const getTitlePartner = createAsyncThunk("partner/getTitlePartner", async (_, { rejectWithValue }) => {
  try {
    const response = await partnerApi.getTitlePartner();
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

// regBecomToPartner
export const regBecomToPartner = createAsyncThunk(
  "partner/regBecomToPartner",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await partnerApi.regBecomToPartner(data);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

// regServicePartner
export const regServicePartner = createAsyncThunk(
  "partner/regServicePartner",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await partnerApi.regServicePartner(data);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

// getAllCategoriesToRegServicePartner
export const getAllCategoriesToRegServicePartner = createAsyncThunk(
  "partner/getAllCategoriesToRegServicePartner",
  async (_, { rejectWithValue }) => {
    try {
      const response = await partnerApi.getAllCategoriesToRegServicePartner();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

// getCategoriesPartner
export const getCategoriesPartner = createAsyncThunk("partner/getCategoriesPartner", async (_, { rejectWithValue }) => {
  try {
    const response = await partnerApi.getCategoriesPartner();
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

// getScheduleByCategoryPartner
export const getScheduleByCategoryPartner = createAsyncThunk(
  "partner/getScheduleByCategoryPartner",
  async (category_id: number, { rejectWithValue }) => {
    try {
      const response = await partnerApi.getScheduleByCategoryPartner({ category_id, limit: 50, client: "web" });
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

// takeJob
export const takeJobPartner = createAsyncThunk(
  "partner/takeJobPartner",
  async (ids: Array<number>, { rejectWithValue }) => {
    try {
      const response = await partnerApi.takeJob({ ids, client: "web" });
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

// getSchedulePartner
export const getAllSchedulePartner = createAsyncThunk(
  "partner/getAllSchedulePartner",
  async (_, { rejectWithValue }) => {
    try {
      const response = await partnerApi.getAllSchedulePartner();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    setPartner: (state, action) => ({ ...state, ...action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInfoTasker.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInfoTasker.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfor = action.payload;
      })
      .addCase(getInfoTasker.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // getRevenueStatisticsPartner
      .addCase(getRevenueStatisticsPartner.pending, (state) => {
        // state.loadinggetRevenueStatisticsPartner = true;
        state.loading = true;
        state.error = null;
      })
      .addCase(getRevenueStatisticsPartner.fulfilled, (state, action) => {
        // state.loadinggetRevenueStatisticsPartner = false;
        state.loading = false;
        state.reportData = action.payload;
      })
      .addCase(getRevenueStatisticsPartner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // getSalaryDataPartner
      .addCase(getSalaryDataPartner.pending, (state) => {
        // state.loadinggetSalaryDataPartner = true;
        state.error = null;
      })
      .addCase(getSalaryDataPartner.fulfilled, (state, action) => {
        // state.loadinggetSalaryDataPartner = false;
        state.paymentData = action.payload;
      })
      .addCase(getSalaryDataPartner.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.payload as string;
      })

      // add bank
      .addCase(addBankPartner.pending, (state) => {
        state.loadingAddBank = true;
        state.error = null;
      })
      .addCase(addBankPartner.fulfilled, (state, action) => {
        state.loadingAddBank = false;
        state.bankInfor = action.payload;
      })
      .addCase(addBankPartner.rejected, (state, action) => {
        state.loadingAddBank = false;
        state.error = action.payload as string;
      })

      // getTitlePartner
      .addCase(getTitlePartner.pending, (state) => {
        state.loadingTitle = true;
        state.error = null;
      })
      .addCase(getTitlePartner.fulfilled, (state, action) => {
        state.loadingTitle = false;
        state.titleData = action.payload;
      })
      .addCase(getTitlePartner.rejected, (state, action) => {
        state.loadingTitle = false;
        state.error = action.payload as string;
      })

      // regServicePartner
      .addCase(regServicePartner.pending, (state) => {
        state.loadingSubCategoriesToRegService = true;
        state.error = null;
      })
      .addCase(regServicePartner.fulfilled, (state, action) => {
        state.loadingSubCategoriesToRegService = false;
        state.subCategoryData = action.payload;
      })
      .addCase(regServicePartner.rejected, (state, action) => {
        state.loadingSubCategoriesToRegService = false;
        state.error = action.payload as string;
      })

      //regBecomToPartner
      .addCase(regBecomToPartner.pending, (state) => {
        state.loadingRegServices = true;
        state.error = null;
      })
      .addCase(regBecomToPartner.fulfilled, (state, action) => {
        state.loadingRegServices = false;
        // state.subCategoryData = action.payload;
      })
      .addCase(regBecomToPartner.rejected, (state, action) => {
        state.loadingRegServices = false;
        state.error = action.payload as string;
      })

      //getCategoriesHomePartner
      .addCase(getCategoriesPartner.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getCategoriesPartner.fulfilled, (state, action) => {
        state.categoriesHome = action.payload?.categories;
        state.bannerHome = action.payload?.banners;
        state.loading = false;
      })
      .addCase(getCategoriesPartner.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      })

      //getScheduleByCategoryPartner
      .addCase(getScheduleByCategoryPartner.pending, (state) => {
        state.error = null;
        state.loadingScheduleByCategoryId = true;
      })
      .addCase(getScheduleByCategoryPartner.fulfilled, (state, action) => {
        state.scheduleByCategoryId = action.payload;
        state.loadingScheduleByCategoryId = false;
      })
      .addCase(getScheduleByCategoryPartner.rejected, (state, action) => {
        state.loadingScheduleByCategoryId = false;
        state.error = action.payload as string;
      })

      //takeJobPartner
      .addCase(takeJobPartner.pending, (state) => {
        state.error = null;
        state.loadingTakeJob = true;
      })
      .addCase(takeJobPartner.fulfilled, (state, action) => {
        state.responseTakeJob = action.payload;
        state.loadingTakeJob = false;
      })
      .addCase(takeJobPartner.rejected, (state, action) => {
        state.loadingTakeJob = false;
        state.error = action.payload as string;
      })

      // getTitlePartner
      .addCase(getAllCategoriesToRegServicePartner.pending, (state) => {
        state.loadingCategoryToRegService = true;
        state.error = null;
      })
      .addCase(getAllCategoriesToRegServicePartner.fulfilled, (state, action) => {
        state.loadingCategoryToRegService = false;
        state.categoriesDataToRegService = action.payload;
      })
      .addCase(getAllCategoriesToRegServicePartner.rejected, (state, action) => {
        state.loadingCategoryToRegService = false;
        state.error = action.payload as string;
      })

      // getSchedulePartner
      .addCase(getAllSchedulePartner.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getAllSchedulePartner.fulfilled, (state, action) => {
        state.allSchedule = action.payload;
        state.loading = false;
      })
      .addCase(getAllSchedulePartner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default partnerSlice.reducer;
export const { setPartner } = partnerSlice.actions;
