import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import HeaderSevice from "../../../../components/HeaderService";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { getNotifyDetailUser, getScheduleDetailUser } from "../../../../redux/Slices/userSlice";
import { formatPrice } from "../../../../utils/func";
import overLoader from "../../../../components/overLoader";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../../helper/localStorage";

export const NotificationDetail = () => {
  const notiID = useLocation().state.id;
  const dispatch = useAppDispatch();
  const notiDetail = useAppSelector((state) => state.user.notiDetail);
  const scheduleInfor = useAppSelector((state) => state.user.secheduDetailUser);
  const loading = useAppSelector((state) => state.user.loading);
  const scheduID = notiDetail?.schedule_id;
  const { t, i18n } = useTranslation(["calendar"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  useEffect(() => {
    dispatch(getNotifyDetailUser(notiID));
  }, [dispatch, notiID]);

  useEffect(() => {
    if (scheduID) {
      dispatch(getScheduleDetailUser(scheduID));
    }
  }, [dispatch, scheduID]);

  return (
    <div className="wrapper shift-notification">
      {loading && overLoader()}
      <HeaderSevice text={t("chi_tiet_cong_viec")} />
      <div className="shift-notification">
        <div className="booking-info">
          <p className="booking-info-title">{t("thong_tin_dat_lich")}</p>
          <table className="booking-info-detail">
            <tbody>
              <tr>
                <td className="booking-info-key">{t("ma_dich_vu")}</td>
                <td className="booking-info-value">{notiDetail?.job_id}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("dich_vu")}</td>
                <td className="booking-info-value">{scheduleInfor?.service}</td>
              </tr>
              {/* <tr>
                  <td className="booking-info-key">Nhóm dịch vụ</td>
                  <td className="booking-info-value">Chăm sóc - điều dưỡng</td>
                </tr> */}
              <tr>
                <td className="booking-info-key">{t("ngay_bat_dau")}</td>
                <td className="booking-info-value">{scheduleInfor?.day_working}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("gio_bat_dau")}</td>
                <td className="booking-info-value">{scheduleInfor?.time_start}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="partner-info">
          <div className="partner-info-name">
            <span className="title">{t("nguoi_thuc_hien")}</span>
            <span className="content">{scheduleInfor?.tasker?.name}</span>
          </div>
          <div className="partner-info-phone">
            <span className="title">{t("so_dien_thoai")}</span>
            <span className="content">{scheduleInfor?.tasker?.telephone}</span>
          </div>
        </div>
        <div className="payment-info">
          <table className="payment-info-detail">
            <tbody>
              <tr>
                <td className="payment-info-key">{t("gia_dich_vu")}</td>
                <td className="payment-info-value price">
                  {/* {scheduleInfor?.price} */}
                  {formatPrice(scheduleInfor?.price)}
                </td>
              </tr>
              <tr>
                <td className="payment-info-key">{t("phuong_thuc_thanh_toan")}</td>
                <td className="payment-info-value">{scheduleInfor?.payment_method_title}</td>
              </tr>
              <tr>
                <td className="payment-info-key">{t("tinh_trang")}</td>
                <td className="payment-info-value">{scheduleInfor?.paid_title}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
