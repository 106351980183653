import React from "react";

import imgBack from "../../assets/icons/icon_back.png";
import imgPhone from "../../assets/icons/icon_phone.png";
import { useNavigate } from "react-router-dom";

interface HeaderServiceProps {
  text: string;
}

const HeaderSevice: React.FC<HeaderServiceProps> = ({ text }) => {
  const navigate = useNavigate();
  return (
    <div className="header">
      <button className="header-back-button">
        <div className="header-back-icon" onClick={() => navigate(-1)}>
          <img src={imgBack} alt="" />
        </div>
      </button>
      <span className="header-text">{text}</span>
      <a href="tel:0985768181" className="header-call">
        <img src={imgPhone} alt="" />
      </a>
    </div>
  );
};

export default HeaderSevice;
