import { Routes, Route, Navigate } from "react-router-dom";
import { Fragment, Suspense } from "react";
import { Spin } from "antd";
import "./i18n";

import "./assets/styles/scss/style.scss";

import Page404 from "./page/404";
import { partnerRoutes, userRoutes, publicRoutes } from "./routes";
import ProtectedRoutes from "./routes/protectedRoutes";

import { getLocalStorage } from "./helper/localStorage";
// import { useAppSelector } from "./redux/hook";
import paths from "./config";

// import disableDevtool from "disable-devtool";

function App() {
  const role = getLocalStorage("role");
  //   const roleCurrent = useAppSelector((state) => state.auth.role);
  //   const access_token = getLocalStorage("access_token");
  const subdomain = window.location.hostname.split(".")[0];

  //   function handleRememberPassword(role: string | null, access_token: string | null, path: string) {
  //     if (role && access_token) {
  //       return path;
  //     } else {
  //       //   return role === "partner" ? "/partner/login" : "/user/login" || "";
  //       return role === "partner" ? paths.loginPartner : paths.login || "";
  //     }
  //   }

  /* PUBLIC ROUTE */
  const publicRoute = publicRoutes.map((route) => {
    const Page = route.component;
    let Layout: any = Fragment;

    if (route.layout) {
      Layout = route.layout;
    } else if (route.layout === null) {
      Layout = Fragment;
    }

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <Layout>
            <Page />
          </Layout>
        }
      />
    );
  });

  /* PARTNER ROUTE */
  const partnerRoute = partnerRoutes.map((route: any) => {
    const Page = route.component;
    let Layout: any = Fragment;

    if (route.layout) {
      Layout = route.layout;
    } else if (route.layout === null) {
      Layout = Fragment;
    }

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          //check if role is user => navigate to partner login
          subdomain !== "partner" && !role && role !== "partner" ? (
            // <Navigate to="/partner/login" />
            <Navigate to={paths.loginPartner} />
          ) : (
            <Layout>
              <Page />
            </Layout>
          )
        }
      />
    );
  });

  /* USER ROUTE */
  const userRoute = userRoutes.map((route: any) => {
    const Page = route.component;
    let Layout: any = Fragment;
    if (route.layout) {
      Layout = route.layout;
    } else {
      Layout = Fragment;
    }
    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          subdomain !== "app" && !role && role !== "user" ? (
            // <Navigate to="/user/login" />
            <Navigate to={paths.login} />
          ) : (
            <Layout>
              <Page />
            </Layout>
          )
        }
      />
    );
  });

  //   const hostName = window.location.hostname;
  //   if (
  //     hostName !== "localhost" &&
  //     hostName !== "bluecare-dev-fe.moncow.com" &&
  //     hostName !== "stg.care.moncow.com"
  //   ) {
  //     disableDevtool({ url: "https://bluecare.vn" });
  //   }
  // && hostName !== "bluecare-dev-fe"
  // disable with out localhost and bluecare-dev-fe.moncow.com
  //   disableDevtool({ url: "https://bluecare.vn" });
  return (
    <div className="App">
      <Suspense fallback={<Spin />}>
        <Routes>
          {/* Default redirect to appropriate home based on role */}
          <Route path="/" element={<Navigate to="/home" />} />
          {/* <Route
            path="/home"
            element={
              //   <Navigate
              //     to={handleRememberPassword(role, access_token, role === "partner" ? "/partner/home" : "/user/home")}
              //   />
              <Navigate
                to={handleRememberPassword(role, access_token, role === "partner" ? paths.homePartner : paths.homeUser)}
              />
            }
          />
          <Route
            path="/home"
            element={
              //   <Navigate
              //     to={handleRememberPassword(
              //       roleCurrent,
              //       access_token,
              //       role === "partner" ? "/partner/home" : "/user/home",
              //     )}
              //   />
              <Navigate
                to={handleRememberPassword(
                  roleCurrent,
                  access_token,
                  role === "partner" ? paths.homePartner : paths.homeUser,
                )}
              />
            }
          /> */}

          {/* {Login and Register} */}
          {publicRoute}

          {/* Page Partner + Page User */}
          <Route element={<ProtectedRoutes />}>
            {/* {[...partnerRoute, ...userRoute]} */}
            {subdomain === "partner" ? (
              partnerRoute
            ) : subdomain === "app" ? (
              userRoute
            ) : (
              <Navigate to="https://bluecare.vn" />
            )}
          </Route>

          {/* Page Not Found */}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
