import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "antd";
import { notification } from "antd";

import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { sendOTP, verifyOTP } from "../../redux/Slices/AuthSlice";
import { getInfoAccount } from "../../redux/Slices/userSlice";
import { getInfoTasker } from "../../redux/Slices/partnerSlice";
// import "../../assets/styles/scss/componentsStyled/_ModalConfirmOTP.scss";
import { DataAccount } from "../../types/common";
import overLoader from "../overLoader";

interface ConfirmOTPProps {
  onClose: () => void;
  userInfor: DataAccount;
  role: string;
}

const ConfirmOTP: React.FC<ConfirmOTPProps> = ({ onClose, userInfor, role }) => {
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [otp, setOTP] = useState<string[]>(["", "", "", "", "", ""]);
  const [resendButtonDisabled, setResendButtonDisabled] = useState<boolean>(true);
  const [api, showPopup] = notification.useNotification();
  const dispatch = useAppDispatch();
  const loadingVerifyOTP = useAppSelector((state: any) => state.auth.loadingVerify);
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    // Đếm ngược thời gian sau khi lấy mã OTP
    if (remainingTime > 0 && resendButtonDisabled) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setResendButtonDisabled(false);
    }
  }, [remainingTime, resendButtonDisabled]);

  useEffect(() => {
    if (role === "user") {
      dispatch(getInfoAccount());
    } else {
      dispatch(getInfoTasker());
    }
  }, [dispatch, role]);

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 3,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  const handleSubmit = async () => {
    const OTPcode = {
      otp: parseInt(otp.join("")),
    };

    try {
      const resultAction = await dispatch(verifyOTP(OTPcode));
      const payload = resultAction.payload as { status: number; message: string };
      if (payload.status === 1) {
        alertSuccess(payload.message);
        setTimeout(() => {
          role === "user" ? dispatch(getInfoAccount()) : dispatch(getInfoTasker());
          onClose();
        }, 1000);
      } else if (payload.status === 0) {
        alertFail(payload.message);
      } else {
        alertFail(payload.message || "Có lỗi xảy ra vui lòng thử lại!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // handle send OTP
  const handleSendOTP = async () => {
    setRemainingTime(30);
    setStatusLoader(true);
    if (userInfor?.telephone_is_real) {
      alertSuccess("Số điện thoại của bạn đã được xác thực!");
    } else {
      try {
        const response: any = await dispatch(sendOTP());
        if (response?.payload?.status === 1) {
          alertSuccess(response?.payload?.message);
          setResendButtonDisabled(true);
          setStatusLoader(false);
        } else if (response?.payload?.status === 0) {
          alertFail(response?.payload?.message);
          setStatusLoader(false);
        } else {
          alertFail(response?.payload?.message);
          setStatusLoader(false);
        }
      } catch (error: any) {
        alertFail(error?.payload?.message);
        setStatusLoader(false);
      }
      //   dispatch(sendOTP()).then((resultAction: any) => {
      //     const payload = resultAction.payload as { message: string };
      //     if (sendOTP.fulfilled.match(resultAction)) {
      //       alertSuccess(payload.message || "Mã OTP đã được gửi!");
      //       setResendButtonDisabled(true);
      //       setStatusLoader(false);
      //     } else if (sendOTP.rejected.match(resultAction)) {
      //       alertFail(payload.message || "Gửi mã Thất Bại! Vui lòng thử lại");
      //       setStatusLoader(false);
      //     }
    }
  };

  // handle change opt input
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newOTP = [...otp];
    newOTP[index] = e.target.value;

    if (e.target.value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
    setOTP(newOTP);
  };

  // handle backspace
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && index > 0 && !otp[index]) {
      inputRefs.current[index - 1]?.focus();
    }
  };
  return (
    <div className="container-modal--otp">
      {showPopup}
      {statusLoader && overLoader()}
      <div className="modal-header">
        <button className="close-button" onClick={onClose}>
          x
        </button>
      </div>
      <Form name="otp_confirmation" autoComplete="off">
        <p className="title-modal--otp">Xác thực số điện thoại</p>
        {/* <p className="subtitle-modal--otp">Mã được gửi đến SĐT của bạn. Vui lòng nhập mã để sử dụng các chức năng</p> */}
        <button
          className="subtitle-modal--otp secondary"
          disabled={remainingTime > 0}
          type="button"
          onClick={handleSendOTP}
        >
          Lấy mã OTP
        </button>
        <Form.Item
          name="otp"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập mã OTP!",
            },
          ]}
        >
          <div className="otp-input-container">
            {otp.map((value, index) => (
              <input
                autoFocus={index === 0}
                key={index}
                className="otp-input"
                type="text"
                inputMode="numeric"
                maxLength={1}
                value={value}
                ref={(input) => (inputRefs.current[index] = input)}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => {
                  handleKeyDown(e, index);
                }}
              />
            ))}
          </div>
        </Form.Item>

        <Button
          block
          type="primary"
          className="primary btn-confirm"
          htmlType="submit"
          onClick={handleSubmit}
          loading={loadingVerifyOTP}
          disabled={otp.includes("")}
        >
          Xác nhận
        </Button>
        {remainingTime > 0 && (
          <div className="resend-container">
            <p>Bạn vẫn chưa nhận được mã? </p>
            <button className="resend-link" onClick={handleSendOTP} aria-disabled={resendButtonDisabled}>
              {resendButtonDisabled && `Lấy lại mã sau ${remainingTime} giây`}
            </button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default ConfirmOTP;
