import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HOME_VI from "../locales/vi/home.json";
import HOME_EN from "../locales/en/home.json";
import MESSAGE_VI from "../locales/vi/message.json";
import MESSAGE_EN from "../locales/en/message.json";
import SERVICE_VI from "../locales/vi/service.json";
import SERVICE_EN from "../locales/en/service.json";
import COMMON_VI from "../locales/vi/common.json";
import COMMON_EN from "../locales/en/common.json";
import AUTH_VI from "../locales/vi/auth.json";
import AUTH_EN from "../locales/en/auth.json";
import SETTING_VI from "../locales/vi/setting.json";
import SETTING_EN from "../locales/en/setting.json";
import CALENDAR_VI from "../locales/vi/calendar.json";
import CALENDAR_EN from "../locales/en/calendar.json";
import BOOKING_VI from "../locales/vi/booking.json";
import BOOKING_EN from "../locales/en/booking.json";
import FAMILY_VI from "../locales/vi/family.json";
import FAMILY_EN from "../locales/en/family.json";
import WALLET_VI from "../locales/vi/wallet.json";
import WALLET_EN from "../locales/en/wallet.json";

export const languages = {
  en: "Tiếng Anh",
  vi: "Tiếng Việt",
} as const;

const resources = {
  en: {
    common: COMMON_EN,
    message: MESSAGE_EN,
    home: HOME_EN,
    service: SERVICE_EN,
    auth: AUTH_EN,
    setting: SETTING_EN,
    calendar: CALENDAR_EN,
    booking: BOOKING_EN,
    family: FAMILY_EN,
    wallet: WALLET_EN,
  },
  vi: {
    common: COMMON_VI,
    message: MESSAGE_VI,
    home: HOME_VI,
    service: SERVICE_VI,
    auth: AUTH_VI,
    setting: SETTING_VI,
    calendar: CALENDAR_VI,
    booking: BOOKING_VI,
    family: FAMILY_VI,
    wallet: WALLET_VI,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "vi",
  ns: ["common", "message", "home", "service", "auth", "setting", "calendar", "booking", "family", "wallet"],
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
