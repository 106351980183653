import axios from "axios";
import { getLocalStorage } from "../helper/localStorage";

// const languageLocal = getLocalStorage("language");

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "content-type": "application/json",
    // lang: languageLocal || "vi",
  },
});

axiosClient.interceptors.request.use((config) => {
  const languageLocal = getLocalStorage("language");
  config.headers.lang = languageLocal || "vi";
  return config;
});

axiosClient.interceptors.request.use(
  (config) => {
    const newConfig = config;
    const token = JSON.parse(localStorage.getItem("access_token"));
    if (token && token !== "undefined" && token !== "null") {
      newConfig.headers.authorization = token;
    }
    return newConfig;
  },
  (error) => Promise.reject(error),
);

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data && response.data.data && response.data.data.rows) {
      return response.data.data.rows;
    }
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  async (errors) => {
    if (errors.response?.status === 401) {
      localStorage.clear();
      const currentUrl = window.location.pathname;
      currentUrl !== "/login" && (window.location.href = "/login");
    }
    return Promise.reject(errors);
  },
);
export default axiosClient;
