import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userApi } from "../../api/userApi";
import { DataAccount } from "../../types/common";

interface UserState {
  userInfor: DataAccount | null;
  error: string | null;
  familyUser: any;
  jobsUser: any;
  notifiUser: any;
  notiDetail: any;
  secheduDetailUser: any;
  statistic: any;
  voucher: any;
  voucherDetail: any;
  loadingFamilies: boolean;
  loading: boolean;
  loadingAdditionUserData: boolean;
  loadingVourcherDetail: boolean;
}

const initialState: UserState = {
  userInfor: null,
  error: null,
  familyUser: null,
  jobsUser: {},
  notifiUser: null,
  notiDetail: null,
  secheduDetailUser: null,
  statistic: null,
  voucher: [],
  voucherDetail: null,
  loading: false,
  loadingFamilies: false,
  loadingAdditionUserData: false,
  loadingVourcherDetail: false,
};

export const getInfoAccount = createAsyncThunk("user/login", async (_, { rejectWithValue }) => {
  try {
    const response = await userApi.getInfoAccount();
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const additionUserData = createAsyncThunk("user/additionUserData", async (data: object, { rejectWithValue }) => {
  try {
    const response = await userApi.additionUserData(data);
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const changePassUser = createAsyncThunk("auth/verifyOTP", async (data: object, { rejectWithValue }) => {
  try {
    const response = await userApi.changePassword(data);
    return response;
  } catch (error: any) {
    return rejectWithValue({ message: error.message });
  }
});

export const getFamilyUser = createAsyncThunk("user/getFamilyUser", async (_, { rejectWithValue }) => {
  try {
    const response = await userApi.getFamilyUser();
    return response;
  } catch (error: any) {
    return rejectWithValue({ message: error.message });
  }
});

export const createFamilyUser = createAsyncThunk("user/createFamilyUser", async (data: {}, { rejectWithValue }) => {
  try {
    const response = await userApi.createFamilyUser(data);
    return response;
  } catch (error: any) {
    return rejectWithValue({ message: error.message });
  }
});

export const updateFamilyUser = createAsyncThunk("user/updateFamilyUser", async (data: {}, { rejectWithValue }) => {
  try {
    const response = await userApi.updateFamilyUser(data);
    return response;
  } catch (error: any) {
    return rejectWithValue({ message: error.message });
  }
});

export const getJobsUser = createAsyncThunk("user/getJobsUser", async (_, { rejectWithValue }) => {
  try {
    const response = await userApi.getJobsUser();
    return response.data;
  } catch (error: any) {
    return rejectWithValue({ message: error.message });
  }
});

export const getNotifyUser = createAsyncThunk("user/getNotifyUser", async (_, { rejectWithValue }) => {
  try {
    const response = await userApi.getNotifyUser();
    return response;
  } catch (error: any) {
    return rejectWithValue({ message: error.message });
  }
});

export const getNotifyDetailUser = createAsyncThunk(
  "user/getNotifyDetailUser",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await userApi.getNotifyDetailUser(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ message: error.message });
    }
  },
);

// getScheduleDetailUser
export const getScheduleDetailUser = createAsyncThunk(
  "user/getScheduleDetailUser",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await userApi.getScheduleDetailUser(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ message: error.message });
    }
  },
);

// getStatisticsUser
export const getStatisticsUser = createAsyncThunk("user/getStatisticsUser", async (_, { rejectWithValue }) => {
  try {
    const response = await userApi.getStatisticsUser();
    return response.data;
  } catch (error: any) {
    return rejectWithValue({ message: error.message });
  }
});

// getAllVoucherUser
export const getAllVoucherUser = createAsyncThunk("user/getAllVoucherUser", async (_, { rejectWithValue }) => {
  try {
    const response = await userApi.getAllVoucherUser();
    return response;
  } catch (error: any) {
    return rejectWithValue({ message: error.message });
  }
});

// getVoucherByIdUser
export const getVoucherByIdUser = createAsyncThunk(
  "user/getVoucherByIdUser",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await userApi.getVoucherByIdUser(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ message: error.message });
    }
  },
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => ({ ...state, ...action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInfoAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInfoAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfor = action.payload;
      })
      .addCase(getInfoAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // change pass
      .addCase(changePassUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePassUser.fulfilled, (state, action) => {
        state.loading = false;
        // state.userInfor = action.payload;
      })
      .addCase(changePassUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      //additionUserData
      .addCase(additionUserData.pending, (state) => {
        state.loadingAdditionUserData = true;
        state.error = null;
      })
      .addCase(additionUserData.fulfilled, (state, action) => {
        state.loadingAdditionUserData = false;
        // state.userInfor = action.payload;
      })
      .addCase(additionUserData.rejected, (state, action) => {
        state.loadingAdditionUserData = false;
        state.error = action.payload as string;
      })

      // get family infor
      .addCase(getFamilyUser.pending, (state) => {
        state.loadingFamilies = true;
        state.error = null;
      })
      .addCase(getFamilyUser.fulfilled, (state, action) => {
        state.loadingFamilies = false;
        state.familyUser = action.payload;
      })
      .addCase(getFamilyUser.rejected, (state, action) => {
        state.loadingFamilies = false;
        state.error = action.payload as string;
      })

      //getJobsUser
      .addCase(getJobsUser.pending, (state) => {
        // state.loadingFamilies = true;
        state.error = null;
      })
      .addCase(getJobsUser.fulfilled, (state, action) => {
        // state.loadingFamilies = false;
        state.jobsUser = action.payload;
      })
      .addCase(getJobsUser.rejected, (state, action) => {
        // state.loadingFamilies = false;
        state.error = action.payload as string;
      })

      //createFamilyUser
      .addCase(createFamilyUser.pending, (state) => {
        state.loadingFamilies = true;
        state.error = null;
      })
      .addCase(createFamilyUser.fulfilled, (state, action) => {
        state.loadingFamilies = false;
        // state.jobsUser = action.payload;
      })
      .addCase(createFamilyUser.rejected, (state, action) => {
        state.loadingFamilies = false;
        state.error = action.payload as string;
      })

      //updateFamilyUser
      .addCase(updateFamilyUser.pending, (state) => {
        state.loadingFamilies = true;
        state.error = null;
      })
      .addCase(updateFamilyUser.fulfilled, (state, action) => {
        state.loadingFamilies = false;
        // state.jobsUser = action.payload;
      })
      .addCase(updateFamilyUser.rejected, (state, action) => {
        state.loadingFamilies = false;
        state.error = action.payload as string;
      })

      // get notify user
      .addCase(getNotifyUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNotifyUser.fulfilled, (state, action) => {
        state.loading = false;
        state.notifiUser = action.payload;
      })
      .addCase(getNotifyUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // get notify detail user
      .addCase(getNotifyDetailUser.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getNotifyDetailUser.fulfilled, (state, action) => {
        state.notiDetail = action.payload;
        state.loading = false;
      })
      .addCase(getNotifyDetailUser.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      })

      // get schedul detail user
      .addCase(getScheduleDetailUser.pending, (state) => {
        state.error = null;
      })
      .addCase(getScheduleDetailUser.fulfilled, (state, action) => {
        state.secheduDetailUser = action.payload;
      })
      .addCase(getScheduleDetailUser.rejected, (state, action) => {
        state.error = action.payload as string;
      })

      // get statistic user
      .addCase(getStatisticsUser.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getStatisticsUser.fulfilled, (state, action) => {
        state.statistic = action.payload;
        state.loading = false;
      })
      .addCase(getStatisticsUser.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      })

      // get all voucher user
      .addCase(getAllVoucherUser.pending, (state) => {
        state.error = null;
      })
      .addCase(getAllVoucherUser.fulfilled, (state, action) => {
        state.voucher = action.payload;
      })
      .addCase(getAllVoucherUser.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      // voucherDetail user
      .addCase(getVoucherByIdUser.pending, (state) => {
        state.error = null;
        state.loadingVourcherDetail = true;
      })
      .addCase(getVoucherByIdUser.fulfilled, (state, action) => {
        state.voucherDetail = action.payload;
        state.loadingVourcherDetail = false;
      })
      .addCase(getVoucherByIdUser.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loadingVourcherDetail = false;
      });
  },
});

export default userSlice.reducer;
export const { setUser } = userSlice.actions;
