import { Link } from "react-router-dom";
import { useEffect } from "react";

import HeaderSevice from "../../../components/HeaderService";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getStatisticsUser } from "../../../redux/Slices/userSlice";
import paths from "../../../config";
import overLoader from "../../../components/overLoader";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";

export const Statistic = () => {
  const dispatch = useAppDispatch();
  const statisticData = useAppSelector((state) => state.user.statistic);
  const loading = useAppSelector((state) => state.user.loading);
  const { t, i18n } = useTranslation(["home"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  useEffect(() => {
    dispatch(getStatisticsUser());
  }, [dispatch]);

  return (
    <div className="wrapper transaction-statistic">
      {loading && overLoader()}
      <HeaderSevice text={t("thong_ke_giao_dich")} />
      <div className="transaction-statistic">
        <h1 className="transaction-statistic-title">{t("thong_ke_giao_dich")}</h1>
        <div className="transaction-statistic-item">
          <Link to={`${paths.theJobsUser}`} className="transaction-statistic-info">
            <p className="transaction-statistic-text">{t("toan_bo_viec")}</p>
            <p className="transaction-statistic-number">{statisticData?.count_total_jobs}</p>
          </Link>
          <Link to={`${paths.theJobsUser}#completed`} className="transaction-statistic-info">
            <p className="transaction-statistic-text">{t("thanh_cong")}</p>
            <p className="transaction-statistic-number">{statisticData?.count_complete_jobs}</p>
          </Link>
        </div>
        <div className="transaction-statistic-item">
          <Link to={`${paths.theJobsUser}#canceled`} className="transaction-statistic-info">
            <p className="transaction-statistic-text">{t("bi_huy")}</p>
            <p className="transaction-statistic-number">{statisticData?.count_cancel_jobs}</p>
          </Link>
          <Link to={`${paths.theJobsUser}#pending`} className="transaction-statistic-info">
            <p className="transaction-statistic-text">{t("dang_cho")}</p>
            <p className="transaction-statistic-number">{statisticData?.count_pending_jobs}</p>
          </Link>
        </div>
      </div>
    </div>
  );
};
