import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderSevice from "../../../components/HeaderService";
import { formatPrice } from "../../../utils/func";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";

export const JobDetail = () => {
  const location = useLocation();
  const { job } = location.state;

  const { t, i18n } = useTranslation(["calendar"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  return (
    <div className="wrapper task-info">
      <HeaderSevice text={t("chi_tiet_cong_viec")} />
      <div className="task-info">
        <div className="booking-info">
          <p className="booking-info-title">{t("thong_tin_dat_lich")}</p>
          <table className="booking-info-detail">
            <tr>
              <td className="booking-info-key">{t("ma_dich_vu")}</td>
              <td className="booking-info-value">{job.id}</td>
            </tr>
            <tr>
              <td className="booking-info-key">{t("dich_vu")}</td>
              <td className="booking-info-value">{job.service.name}</td>
            </tr>
            <tr>
              <td className="booking-info-key">{t("nhom_dich_vu")}</td>
              <td className="booking-info-value">{job.service.category}</td>
            </tr>
            <tr>
              <td className="booking-info-key">{t("gio_bat_dau")}</td>
              <td className="booking-info-value">{job.detail.time_start}</td>
            </tr>
            <tr>
              <td className="booking-info-key">{t("ngay_bat_dau")}</td>
              <td className="booking-info-value">{job.detail.day_start}</td>
            </tr>
            {job.detail.num_day ? (
              <tr>
                <td className="booking-info-key">{t("so_ngay")}</td>
                <td className="booking-info-value">{job.detail.num_day}</td>
              </tr>
            ) : (
              <>
                <tr>
                  <td className="booking-info-key">{t("gio_ket_thuc")}</td>
                  <td className="booking-info-value">{job.detail.time_end}</td>
                </tr>
                <tr>
                  <td className="booking-info-key">{t("ngay_ket_thuc")}</td>
                  <td className="booking-info-value">{job.detail.day_end}</td>
                </tr>
              </>
            )}
          </table>
        </div>
        <div className="user-info">
          <div className="user-info-name">
            <span className="title">{t("nguoi_su_dung_dich_vu")}</span>
            <span className="content">{job.user}</span>
          </div>
          <div className="user-info-address">
            <span className="title">{t("dia_chi_su_dung_dich_vu")}</span>
            <span className="content">{job.address}</span>
          </div>
        </div>
        <div className="payment-info">
          <table className="payment-info-detail">
            <tr>
              <td className="payment-info-key">{t("gia_dich_vu")}</td>
              <td className="payment-info-value price">
                {/* {job.price} */}
                {formatPrice(Number(job?.price))}
              </td>
            </tr>
            <tr>
              <td className="payment-info-key">{t("phuong_thuc_thanh_toan")}</td>
              <td className="payment-info-value">{job.payment_method_title}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};
