import { useEffect, useState } from "react";
// import type { PaginationProps } from "antd";
import { Button, Modal } from "antd";

import HeaderSevice from "../../../components/HeaderService";
import icon_add_bg from "../../../assets/icons/icon_add.png";
import overLoader from "../../../components/overLoader";

import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { formatPrice } from "../../../utils/func";
import { SchedulePartner } from "../../../types/common";
import { getScheduleByCategoryPartner } from "../../../redux/Slices/partnerSlice";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";
import paths from "../../../config";
// import dayjs from "dayjs";

export const Task = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const scheduleData = useAppSelector((state) => state.partner.scheduleByCategoryId?.datas);
  const loadingScheduleByCategoryId = useAppSelector((state) => state.partner.loadingScheduleByCategoryId);
  const { t, i18n } = useTranslation(["calendar"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);
  useEffect(() => {
    dispatch(getScheduleByCategoryPartner(state?.category_id));
  }, [state?.category_id, dispatch]);

  // const onShowSizeChange: PaginationProps["onShowSizeChange"] = (current, pageSize) => {
  //   // console.log(current, pageSize);
  // };

  // const handleChangePage: PaginationProps["onChange"] = (currentPage) => {
  //   console.log("current", currentPage);
  //   setPage(currentPage);
  // };

  const LocalizedModal = () => {
    const [open, setOpen] = useState(true);
    return (
      <>
        <Modal
          title={t("khong_co_cong_viec_nao")}
          open={open}
          footer={[
            <Button
              type="primary"
              className="btn btn-primary"
              onClick={() => {
                setOpen(false);
                // navigate("/partner/home");
                navigate(paths.homePartner);
              }}
              key="submit"
            >
              OK
            </Button>,
          ]}
          centered
        >
          <p>{t("vui_long_quay_lai_sau")}</p>
        </Modal>
      </>
    );
  };
  //   const scheduleDataConvert = Object.keys(scheduleData)?.map((key) => scheduleData[key]);
  const scheduleDataConvert = scheduleData ? Object.keys(scheduleData).map((key) => scheduleData[key]) : [];
  const RenderListTask = (): JSX.Element => {
    return scheduleDataConvert?.length > 0 ? (
      <>
        {scheduleDataConvert?.map((item: SchedulePartner, index: number) => (
          <div className="task-item" key={index}>
            <div className="task-icon">
              <img src={icon_add_bg} alt="" />
            </div>
            <div className="task-info">
              <h2 className="task-info-name">{item.job.service.name}</h2>
              <div className="task-info-detail">
                <div className="task-info-detail-item">
                  <span className="task-info-detail-key">{t("ngay_bat_dau")}: </span>
                  <span className="task-info-detail-value">{item.job.detail.day_start}</span>
                </div>
                <div className="task-info-detail-item">
                  <span className="task-info-detail-key">{t("gio_bat_dau")}: </span>
                  <span className="task-info-detail-value">{item.job.detail.time_start}</span>
                </div>
                <div className="task-info-detail-item">
                  <span className="task-info-detail-key">{t("dia_chi")}: </span>
                  <span className="task-info-detail-value">{item.job.address}</span>
                </div>
                <div className="task-info-detail-item">
                  <span className="task-info-detail-key">{t("nguoi_dat_dich_vu")}: </span>
                  <span className="task-info-detail-value">{item.job.user}</span>
                </div>
                <div className="task-info-detail-item">
                  <span className="task-info-detail-key">{t("tong_tien")}: </span>
                  <span className="task-info-detail-value price">{formatPrice(+item.job.price)}</span>
                </div>
              </div>
              <Link
                // to="/partner/task/detail"
                to={paths.taskDetailPartner}
                state={{ item }}
                className="task-info-more"
                //   onClick={() =>
                //     navigate("/partner/task/detail", { state: { job: item.job, user: item.user, id: item.id } })
                //   }
              >
                {t("xem_chi_tiet")}
              </Link>
            </div>
          </div>
        ))}
      </>
    ) : (
      <LocalizedModal />
    );
  };

  return (
    <div className="wrapper task-list">
      <HeaderSevice text={state?.name_category} />
      <div className="task-list">{loadingScheduleByCategoryId ? overLoader() : <RenderListTask />}</div>
      {/* pagination */}
      {/* <div className="footer-pagination">
        <Pagination
          responsive
          showSizeChanger
          onChange={handleChangePage}
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={5}
          // total={scheduleData?.meta?.total}
          total={50}
        />
      </div> */}
    </div>
  );
};
