import { useEffect, useState } from "react";
import HeaderSevice from "../../../components/HeaderService";
import { useAppSelector } from "../../../redux/hook";
import { serviceApi } from "../../../api/serviceApi";
import { notification } from "antd";
import overLoader from "../../../components/overLoader";
import dayjs from "dayjs";
import local, { getLocalStorage } from "../../../helper/localStorage";
import { formatPrice, removeAccents } from "../../../utils/func";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import paths from "../../../config";

export const ServiceConfirmation = () => {
  //   const user_id = useLocation().state.user_id;
  const user = useAppSelector((state) => state.user);
  const service = useAppSelector((state) => state.service);
  const templateType = useAppSelector((state) => state.service.templateType);
  const paramsType = useAppSelector((state) => state.service.paramsType);
  const [api, showPopup] = notification.useNotification();
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const data_booking = local.StorageGetSecure("data_booking");
  const fm_info = user?.familyUser?.datas?.find((item: any) => item?.fm_id === data_booking?.fm_id);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["booking"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);
  useEffect(() => {
    // templateType === 3 && !paramsType && navigate("/user/home");
    templateType === 3 && !paramsType && navigate(paths.homeUser);
  }, [paramsType, navigate, templateType]);

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 2,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  const province = removeAccents(
    data_booking.city.replace("Thành phố", "").replace("Tỉnh", "").replaceAll(" ", "").trim().toLowerCase(),
  );
  const handleConfirm = async () => {
    setStatusLoader(true);
    try {
      const data = {
        id: service?.serviceDetail?.id,
        fm_id: data_booking?.fm_id ? data_booking?.fm_id : "",
        time_start: data_booking?.time_start,
        day_start: data_booking?.day_start,
        time_end: data_booking?.time_end,
        day_end: data_booking?.day_end,
        num_day: data_booking?.num_day,
        address: `${data_booking?.address}, ${data_booking?.ward}, ${data_booking?.district}, ${data_booking?.city}`,
        payment_method: data_booking?.payment_method,
        params_type: paramsType !== 0 ? paramsType : "",
        voucher_id: service?.servicePrice?.data?.voucher_id,
        note: data_booking?.note,
        province: province === "hanoi" || province === "hochiminh" ? province : "",
      };
      const resData: any = await serviceApi.requestService(data);
      //   console.log(resData);
      if (resData.status === 1) {
        setStatusLoader(false);
        alertSuccess(resData.message);
        data_booking?.payment_method === 2 && handlePaymentEbanking(resData.data.id);
        setTimeout(() => {
          //   window.location.href = "/user/home";
          //   data_booking?.payment_method !== 2 && navigate("/user/home");
          data_booking?.payment_method !== 2 && navigate(paths.homeUser);
          local.StorageRemoveSecure("data_booking");
        }, 2000);
      } else if (resData.status === 0) {
        setStatusLoader(false);
        alertFail(resData.message);
      } else {
        setStatusLoader(false);
        alertFail("Có lỗi xảy ra, vui lòng thử lại!");
      }
    } catch (error) {
      setStatusLoader(false);
    }
  };
  const handlePaymentEbanking = async (job_id: number) => {
    setStatusLoader(true);
    const token = localStorage.getItem("access_token")?.replaceAll('"', "");
    try {
      const response: any = await serviceApi.paymentEbanking({
        token,
        job_id,
      });
      setStatusLoader(false);
      // setHtmlResponse(response);
      setTimeout(() => {
        // navigate("/user/booking/transfer_info", { state: response });
        navigate(paths.transferInfo, { state: response });
      }, 1000);
    } catch (error) {
      setStatusLoader(false);
    }
  };
  //   console.log(data_booking);
  return (
    <div className="wrapper service-confirmation">
      {showPopup}
      {statusLoader ? overLoader() : ""}
      <HeaderSevice text={t("xac_nhan_dich_vu")} />
      <div className="service-confirmation">
        <div className="booking-info">
          <p className="booking-info-title">{t("thong_tin_dat_lich")}</p>
          <table className="booking-info-detail">
            <tbody>
              <tr>
                <td className="booking-info-key">{t("ma_dich_vu")}</td>
                <td className="booking-info-value">{service?.serviceDetail?.service_code}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("dich_vu")}</td>
                <td className="booking-info-value">{service?.serviceDetail?.name}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("nhom_dich_vu")}</td>
                <td className="booking-info-value">{service?.categoryName?.category_name}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("gio_bat_dau")}</td>
                <td className="booking-info-value">{data_booking?.time_start}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("gio_ket_thuc")}</td>
                <td className="booking-info-value">{data_booking?.time_end}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("ngay_bat_dau")}</td>
                <td className="booking-info-value">{dayjs(data_booking?.day_start).format("DD-MM-YYYY")}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("so_ngay")}</td>
                <td className="booking-info-value">
                  {templateType !== 3 || (templateType === 3 && paramsType === 2)
                    ? data_booking?.num_day
                    : dayjs(data_booking?.day_end).diff(data_booking?.day_start, "day")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="user-info">
          <div className="user-info-name">
            <span className="title">{t("nguoi_su_dung_dich_vu")}</span>
            <span className="content">{fm_info?.name ? fm_info?.name : user?.userInfor?.name}</span>
          </div>
          <div className="user-info-address">
            <span className="title">{t("dia_chi_su_dung_dich_vu")}</span>
            <span className="content">{`${data_booking?.address}, ${data_booking?.ward}, ${data_booking?.district}, ${data_booking?.city}`}</span>
          </div>
        </div>
        <div className="payment-info">
          <table className="payment-info-detail">
            <tbody>
              <tr>
                <td className="payment-info-key">{t("gia_dich_vu")}</td>
                <td className="payment-info-value price">
                  {/* {service?.servicePrice.data?.money}đ */}
                  {formatPrice(service?.servicePrice.data?.money.toString().replaceAll(",", ""))}
                </td>
              </tr>
              <tr>
                <td className="payment-info-key">{t("phuong_thuc_thanh_toan")}</td>
                <td className="payment-info-value">
                  {data_booking?.payment_method === 0
                    ? t("tien_mat")
                    : data_booking?.payment_method === 1
                    ? t("vi")
                    : data_booking?.payment_method === 2 && t("chuyen_khoan")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="service-confirmation-button">
          <button className="booking-button primary" onClick={handleConfirm}>
            {t("xac_nhan")}
          </button>
        </div>
      </div>
    </div>
  );
};
