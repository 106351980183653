import { useEffect } from "react";
import HeaderService from "../../../components/HeaderService";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getRevenueStatisticsPartner } from "../../../redux/Slices/partnerSlice";
import { Reports } from "../../../types/common";
import LoadingParagraph from "../../../components/skeleton";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";
import { formatPrice } from "../../../utils/func";

export const Report = () => {
  const dispatch = useAppDispatch();
  const reportData = useAppSelector((state) => state.partner.reportData);
  const loading = useAppSelector((state) => state.partner.loading);
  const { t, i18n } = useTranslation(["home"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  useEffect(() => {
    dispatch(getRevenueStatisticsPartner());
  }, [dispatch]);

  return (
    <div className="wrapper report">
      <HeaderService text={t("bao_cao")} />
      <div className="report content-container">
        {loading && <LoadingParagraph />}
        {reportData?.datas?.length > 0 &&
          reportData.datas.map((item: Reports, index: number) => (
            <div key={index} className="report-item">
              <h2 className="report-item-title">{item.title}</h2>
              <span className="report-item-count">{formatPrice(Number(item.money))}</span>
            </div>
          ))}
      </div>
    </div>
  );
};
