import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { serviceApi } from "../../api/serviceApi";
import { CategoryData, ServiceData } from "../../types/service";
import { calcPriceForm } from "../../types/common";

interface ServiceState {
  loadingService: boolean;
  loading: boolean;
  loadingBankData: boolean;
  loadingCity: boolean;
  loadingServiceDetail: boolean;
  loadingCaclPrice: boolean;
  cities: any;
  paramsType: number;
  error: string | null;
  caregory: CategoryData[];
  caregoryPartner: CategoryData[];
  scheduleStatistics: any;
  serviceList: ServiceData[];
  serviceDetail: any;
  categoryName: any;
  allBankData: [];
  servicePrice: any;
  templateType: number;
  addressSuggestions: any;
}

const initialState: ServiceState = {
  loadingService: false,
  loading: false,
  loadingBankData: false,
  loadingCity: false,
  loadingServiceDetail: false,
  loadingCaclPrice: false,
  cities: null,
  paramsType: 0,
  error: null,
  caregory: [],
  caregoryPartner: [],
  scheduleStatistics: null,
  serviceList: [],
  serviceDetail: null,
  categoryName: "",
  allBankData: [],
  servicePrice: "",
  templateType: 0,
  addressSuggestions: null,
};

// category user
export const getCategory = createAsyncThunk("service/getCategory", async (_, { rejectWithValue }) => {
  try {
    const response = await serviceApi.getCategory();
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

//category partner
export const getCategoryForTasker = createAsyncThunk("service/getCategoryForTasker", async (_, { rejectWithValue }) => {
  try {
    const response = await serviceApi.getCategoryForTasker();
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

// statistics partner
export const getScheduleStatistics = createAsyncThunk(
  "service/getScheduleStatistics",
  async (_, { rejectWithValue }) => {
    try {
      const response = await serviceApi.getScheduleStatistics();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

// service list
export const getServices = createAsyncThunk("service/getServices", async (category_id: string, { rejectWithValue }) => {
  try {
    const response = await serviceApi.getServices(category_id);
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

// getServiceDetail
export const getServiceDetail = createAsyncThunk(
  "service/getServiceDetail",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await serviceApi.getServiceDetail(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

//calculatePrice
export const calculatePrice = createAsyncThunk(
  "service/calculatePrice",
  async (data: calcPriceForm, { rejectWithValue }) => {
    try {
      const response = await serviceApi.calculatePrice(data);
      return response;
    } catch (error: any) {
      const response = error?.response?.data;
      return rejectWithValue(response);
    }
  },
);

// getAllBank
export const getAllBank = createAsyncThunk("service/getAllBank", async (_, { rejectWithValue }) => {
  try {
    const response = await serviceApi.getAllBank();
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

// getAllCity
export const getAllCity = createAsyncThunk("service/getAllCity", async (_, { rejectWithValue }) => {
  try {
    const response = await serviceApi.getAllCity();
    const data = response;
    return data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

// getAddresSuggestions
export const getAddressSuggestions = createAsyncThunk(
  "service/getAddressSuggestions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await serviceApi.getAddressSuggestions();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setService: (state, action) => ({ ...state, ...action.payload }),
    setCategory: (state, action) => ({ ...state, ...action.payload }),
    setTemplateType: (state, action) => {
      state.templateType = action.payload;
    },
    setParamsType: (state, action) => {
      state.paramsType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // category user
      .addCase(getCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.caregory = action.payload;
      })
      .addCase(getCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // category partner
      .addCase(getCategoryForTasker.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCategoryForTasker.fulfilled, (state, action) => {
        state.loading = false;
        state.caregoryPartner = action.payload;
      })
      .addCase(getCategoryForTasker.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // statistics partner
      .addCase(getScheduleStatistics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getScheduleStatistics.fulfilled, (state, action) => {
        state.loading = false;
        state.scheduleStatistics = action.payload;
      })
      .addCase(getScheduleStatistics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      //service
      .addCase(getServices.pending, (state) => {
        state.loadingService = true;
        state.error = null;
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.loadingService = false;
        state.serviceList = action.payload.data;
        state.categoryName = action.payload;
      })
      .addCase(getServices.rejected, (state, action) => {
        state.loadingService = false;
        state.error = action.payload as string;
      })

      // getServiceDetail
      .addCase(getServiceDetail.pending, (state) => {
        state.loadingServiceDetail = true;
        state.error = null;
      })
      .addCase(getServiceDetail.fulfilled, (state, action) => {
        state.loadingServiceDetail = false;
        state.serviceDetail = action.payload;
      })
      .addCase(getServiceDetail.rejected, (state, action) => {
        state.loadingServiceDetail = false;
        state.error = action.payload as string;
      })

      // get calc price
      .addCase(calculatePrice.pending, (state) => {
        state.loadingCaclPrice = true;
        state.error = null;
      })
      .addCase(calculatePrice.fulfilled, (state, action) => {
        state.loadingCaclPrice = false;
        state.servicePrice = action.payload;
      })
      .addCase(calculatePrice.rejected, (state, action) => {
        state.loadingCaclPrice = false;
        state.error = action.payload as string;
      })

      // get all city
      .addCase(getAllCity.pending, (state) => {
        state.loadingCity = true;
        state.error = null;
      })
      .addCase(getAllCity.fulfilled, (state, action) => {
        state.loadingCity = false;
        state.cities = action.payload;
      })
      .addCase(getAllCity.rejected, (state, action) => {
        state.loadingCity = false;
        state.error = action.payload as string;
      })

      // get address suggestions
      .addCase(getAddressSuggestions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAddressSuggestions.fulfilled, (state, action) => {
        state.loading = false;
        state.addressSuggestions = action.payload;
      })
      .addCase(getAddressSuggestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // get all bank
      .addCase(getAllBank.pending, (state) => {
        state.loadingBankData = true;
        state.error = null;
      })
      .addCase(getAllBank.fulfilled, (state, action) => {
        state.loadingBankData = false;
        state.allBankData = action.payload.data;
      })
      .addCase(getAllBank.rejected, (state, action) => {
        state.loadingBankData = false;
        state.error = action.payload as string;
      });
  },
});

export default serviceSlice.reducer;
export const { setService, setCategory, setTemplateType, setParamsType } = serviceSlice.actions;
