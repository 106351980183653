import React, { useEffect, useState } from "react";
import { Form, Input, DatePicker, notification, Select } from "antd";
import dayjs from "dayjs";
// import moment from "moment";

import HeaderService from "../../../components/HeaderService";
import image_user from "../../../assets/icons/icon_user.png";

import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { updateFamilyUser } from "../../../redux/Slices/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";
import overLoader from "../../../components/overLoader";
import { validatePhoneNumber } from "../../../utils/validate";
import paths from "../../../config";

export const FormUpdateRelative = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [api, showPopup] = notification.useNotification();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.user.loadingFamilies);
  const [file, setFile] = useState();
  const [urlImgPreview, setUrlImgPreview] = useState<string>("");
  const { t, i18n } = useTranslation(["family", "setting", "common"]);
  const languageLocal: any = getLocalStorage("language");
  const [form] = Form.useForm();
  //   console.log(location?.state);

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];

  const onFinish = async (values: any) => {
    // if (values.birth) {
    //   values.birth = moment(values.birth).format("YYYY-MM-DD");
    // }
    const data = new FormData();
    if (file) {
      data.append("avatar", file);
      data.append("name", values.name);
      data.append("address", values.address);
      data.append("birth", dayjs(values.birth).format("YYYY-MM-DD"));
      data.append("blood_group", values.blood_group);
      data.append("blood_pressure", values.blood_pressure);
      data.append("blood_sugar", values.blood_sugar);
      data.append("city", values.city);
      data.append("disease_profile", values.disease_profile);
      data.append("gender", values.gender);
      data.append("relationship", values.relationship);
      data.append("telephone", values.telephone);
      data.append("fm_id", location?.state?.fm_id);
    } else {
      data.append("avatar", "");
      data.append("name", values.name);
      data.append("address", values.address);
      data.append("birth", dayjs(values.birth).format("YYYY-MM-DD"));
      data.append("blood_group", values.blood_group);
      data.append("blood_pressure", values.blood_pressure);
      data.append("blood_sugar", values.blood_sugar);
      data.append("city", values.city);
      data.append("disease_profile", values.disease_profile);
      data.append("gender", values.gender);
      data.append("relationship", values.relationship);
      data.append("telephone", values.telephone);
      data.append("fm_id", location?.state?.fm_id);
    }
    try {
      const response: any = await dispatch(updateFamilyUser(data));
      //   console.log(response.payload.message);
      if (response.payload.status === 1) {
        alertSuccess(response.payload.message);
        setTimeout(() => {
          //   navigate("/user/family_infor");
          navigate(paths.familyInfoUser);
        }, 1000);
      } else if (response.payload.status === 0) {
        alertFail(response.payload.message);
      } else {
        alertFail(response.payload.message);
      }
    } catch (error: any) {
      alertFail(error.message);
    }
    // try {
    //   dispatch(updateFamilyUser(data)).then((resultAction: any) => {
    //     const payload = resultAction.payload as { message: string };
    //     if (updateFamilyUser.fulfilled.match(resultAction)) {
    //       alertSuccess(payload.message || "Cập nhật thông tin thành công");
    //       setTimeout(() => {
    //         navigate("/user/family_infor");
    //       }, 1000);
    //     } else if (updateFamilyUser.rejected.match(resultAction)) {
    //       alertFail(payload.message || "Cập nhật thất bại, vui lòng thử lại!");
    //     }
    //   });
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const handleImageChange = (event: any) => {
    const fileImg = event.target.files[0];
    if (fileImg) {
      if (!allowedImageTypes.includes(fileImg.type)) {
        alertFail("Vui lòng chọn file ảnh");
        return;
      }
      setFile(fileImg);
      const readerFile = new FileReader();
      readerFile.onload = (event) => {
        if (event.target && event.target.result) {
          const urlImgPreview = event.target.result as string;
          setUrlImgPreview(urlImgPreview);
        }
      };
      readerFile.readAsDataURL(fileImg);
    }
  };

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 2,
    });
  };

  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  return (
    <div className="wrapper add-relative">
      {showPopup}
      {loading && overLoader()}
      <HeaderService text={t("cap_nhat_thong_tin")} />
      <div className="add-relative content-container has-bottom-button">
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            name: location?.state?.name,
            gender: location?.state?.gender,
            birth: dayjs(location?.state?.birth, "YYYY-MM-DD"),
            telephone: location?.state?.telephone,
            address: location?.state?.address,
            city: location?.state?.city,
            relationship: location?.state?.relationship,
            blood_group: location?.state?.blood_group,
            blood_pressure: Number(location?.state?.blood_pressure),
            blood_sugar: location?.state?.blood_sugar,
            disease_profile: location?.state?.disease_profile,
          }}
        >
          <div className="relative-avt">
            <div className="relative-avt-img">
              <input type="file" className="relative-avt-upload" onChange={handleImageChange} />
              <img
                src={`${urlImgPreview ? urlImgPreview : location?.state.avatar ? location?.state.avatar : image_user}`}
                alt=""
              />
            </div>
            <span className="relative-avt-text">{t("cap_nhat_thong_tin")}</span>
          </div>
          <div className="relative-info">
            <Form.Item
              label={t("setting:ho_ten")}
              name="name"
              rules={[{ required: true, message: t("common:yeu_cau_khong_de_trong") }]}
              className="relative-info-item"
            >
              <Input className="relative-info-input" />
            </Form.Item>
            <Form.Item
              label={t("setting:gioi_tinh")}
              name="gender"
              rules={[{ required: true, message: t("common:yeu_cau_khong_de_trong") }]}
              className="relative-info-item"
            >
              <Select
                options={[
                  {
                    label: "Nam",
                    value: 0,
                  },
                  {
                    label: "Nữ",
                    value: 1,
                  },
                  {
                    label: "Khác",
                    value: 2,
                  },
                ]}
                variant="borderless"
                className="relative-info-input"
              />
            </Form.Item>
            <Form.Item
              label={t("setting:ngay_sinh")}
              name="birth"
              rules={[{ required: true, message: t("common:yeu_cau_khong_de_trong") }]}
              className="relative-info-item"
            >
              <DatePicker format={["DD-MM-YYYY", "DD/MM/YYYY"]} className="relative-info-input" />
            </Form.Item>
            <Form.Item
              label={t("setting:so_dien_thoai")}
              name="telephone"
              rules={[
                { required: true, message: t("common:yeu_cau_khong_de_trong") },
                {
                  validator: validatePhoneNumber,
                  message: t("setting:so_dien_thoai_khong_hop_le"),
                },
              ]}
              className="relative-info-item"
            >
              <Input className="relative-info-input" />
            </Form.Item>
            <Form.Item
              label={t("setting:dia_chi")}
              name="address"
              rules={[{ required: true, message: t("common:yeu_cau_khong_de_trong") }]}
              className="relative-info-item"
            >
              <Input className="relative-info-input" />
            </Form.Item>
            <Form.Item
              label={t("thanh_pho")}
              name="city"
              rules={[{ required: true, message: t("common:yeu_cau_khong_de_trong") }]}
              className="relative-info-item"
            >
              {/* <Input className="relative-info-input" /> */}
              <Select
                options={[
                  {
                    label: "Hà Nội",
                    value: "Hà Nội",
                  },
                  {
                    label: "Hồ Chí Minh",
                    value: "Hồ Chí Minh",
                  },
                  {
                    value: "Hải Phòng",
                    label: "Hải Phòng",
                  },
                  {
                    value: "Quảng Ninh",
                    label: "Quảng Ninh",
                  },
                  {
                    value: "Nghệ An",
                    label: "Nghệ An",
                  },
                  {
                    value: "Thanh Hoá",
                    label: "Thanh Hoá",
                  },
                  {
                    value: "Bắc Ninh",
                    label: "Bắc Ninh",
                  },
                  {
                    value: "Thái Nguyên",
                    label: "Thái Nguyên",
                  },
                  {
                    value: "Hưng Yên",
                    label: "Hưng Yên",
                  },
                  {
                    value: "Đà Nẵng",
                    label: "Đà Nẵng",
                  },
                  {
                    value: "Huế",
                    label: "Huế",
                  },
                  {
                    value: "Vũng Tàu",
                    label: "Vũng Tàu",
                  },
                  {
                    value: "Đồng Nai",
                    label: "Đồng Nai",
                  },
                  {
                    value: "Bình Dương",
                    label: "Bình Dương",
                  },
                  {
                    value: "Lâm Đồng",
                    label: "Lâm Đồng",
                  },
                  {
                    value: "Long An",
                    label: "Long An",
                  },
                  {
                    value: "Cần Thơ",
                    label: "Cần Thơ",
                  },
                ]}
                variant="borderless"
                className="relative-info-input"
              />
            </Form.Item>
            <Form.Item
              label={t("quan_he")}
              name="relationship"
              rules={[{ required: true, message: t("common:yeu_cau_khong_de_trong") }]}
              className="relative-info-item"
            >
              <Select
                options={[
                  {
                    label: "Bố",
                    value: 1,
                  },
                  {
                    label: "Mẹ",
                    value: 2,
                  },
                  {
                    label: "Ông",
                    value: 3,
                  },
                  {
                    label: "Bà",
                    value: 4,
                  },
                  {
                    label: "Anh/Em trai",
                    value: 5,
                  },
                  {
                    label: "Chị/em gái",
                    value: 6,
                  },
                  {
                    label: "Con",
                    value: 7,
                  },
                ]}
                variant="borderless"
                className="relative-info-input"
              />
            </Form.Item>
            <Form.Item
              label={t("nhom_mau")}
              name="blood_group"
              rules={[{ required: true, message: t("common:yeu_cau_khong_de_trong") }]}
              className="relative-info-item"
            >
              <Select
                options={[
                  {
                    label: "A",
                    value: "A",
                  },
                  {
                    label: "B",
                    value: "B",
                  },
                  {
                    label: "AB",
                    value: "AB",
                  },
                  {
                    label: "O",
                    value: "O",
                  },
                ]}
                variant="borderless"
                className="relative-info-input"
              />
            </Form.Item>
            <Form.Item label={t("huyet_ap")} required className="relative-info-item">
              <Form.Item
                name="blood_pressure"
                rules={[
                  { required: true, message: t("common:yeu_cau_khong_de_trong") },
                  {
                    pattern: /^\d{3}$/,
                    message: t("yeu_cau_3_ki_tu_so"),
                  },
                ]}
                noStyle
              >
                <Input className="relative-info-input" />
              </Form.Item>
              <span className="relative-info-text">mmHg</span>
            </Form.Item>
            <Form.Item label={t("duong_huyet")} required className="relative-info-item">
              <Form.Item
                name="blood_sugar"
                rules={[
                  {
                    required: true,
                    message: t("common:yeu_cau_khong_de_trong"),
                  },
                  {
                    validator: (_, value) => {
                      if (value >= 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t("yeu_cau_so_duong")));
                    },
                  },
                ]}
                noStyle
              >
                <Input className="relative-info-input" />
              </Form.Item>
              <span className="relative-info-text">mg/dL</span>
            </Form.Item>
            <Form.Item
              label={t("tien_su_benh")}
              name="disease_profile"
              rules={[{ required: true, message: t("common:yeu_cau_khong_de_trong") }]}
              className="relative-info-item"
            >
              <Input.TextArea className="relative-info-input textarea" />
            </Form.Item>
          </div>
        </Form>
      </div>
      <div className="booking-button-wrapper">
        <button type="submit" className="booking-button primary" onClick={() => form.submit()}>
          {t("common:luu")}
        </button>
      </div>
    </div>
  );
};
