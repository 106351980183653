import { useEffect, useState } from "react";
import HeaderSevice from "../../../components/HeaderService";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getAllSchedulePartner } from "../../../redux/Slices/partnerSlice";
import { CalendarPartner } from "../../../types/common";
import dayjs from "dayjs";
import { partnerApi } from "../../../api/partnerApi";
import { Button, Modal, notification, Rate, Tabs, TabsProps } from "antd";
import overLoader from "../../../components/overLoader";
import icon_calendar from "../../../assets/icons/icon_calendar.png";
import icon_user from "../../../assets/icons/icon_user.png";
import TextArea from "antd/es/input/TextArea";
import local, { getLocalStorage } from "../../../helper/localStorage";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import paths from "../../../config";

export const CalenderPartner = () => {
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [openRating, setOpenRating] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<CalendarPartner>();
  const [rate, setRate] = useState<number>(0);
  const dispatch = useAppDispatch();
  const [api, showPopup] = notification.useNotification();
  const allSchedule = useAppSelector((state) => state.partner.allSchedule);
  const loadingSchedule = useAppSelector((state) => state.partner.loading);
  const data_rating = local.StorageGetSecure("data_rating");
  const { t, i18n } = useTranslation(["calendar"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  useEffect(() => {
    dispatch(getAllSchedulePartner());
  }, [dispatch]);

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 2,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  const formatPrice = (price: string) => {
    return new Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(Number(price));
  };

  const handleStartJob = async (id: string) => {
    setStatusLoader(true);
    try {
      const response: any = await partnerApi.startJob({ id });
      //   console.log(response);
      if (response.status === 1) {
        dispatch(getAllSchedulePartner());
        setStatusLoader(false);
        alertSuccess(response.message);
      } else if (response.status === 0) {
        alertFail(response.message);
      }
    } catch (error: any) {
      setStatusLoader(false);
      alertFail(error.response.data.message);
    }
  };
  //   const handleFinishJob = async (id: string) => {
  //     setStatusLoader(true);
  //     try {
  //       const response: any = await partnerApi.finishJob({ id });
  //       if (response.status === 1) {
  //         dispatch(getAllSchedulePartner());
  //         setStatusLoader(false);
  //         alertSuccess(response.message);
  //       } else if (response.status === 0) {
  //         alertFail(response.message);
  //       }
  //     } catch (error: any) {
  //       setStatusLoader(false);
  //       alertFail(error.response.data.message);
  //     }
  //   };
  const handleRateUser = async () => {
    setStatusLoader(true);
    const data = {
      rate: data_rating?.rate,
      review: data_rating?.review,
      user_id: selectedUser?.user?.id,
      id: selectedUser?.id,
    };
    if (data?.rate === 0 || data?.rate === undefined || data?.review === "") {
      setStatusLoader(false);
      alertFail(t("vui_long_nhap_day_du_thong_tin"));
    } else {
      try {
        const response: any = await partnerApi.rateUser(data);
        if (response.status === 1) {
          setStatusLoader(false);
          alertSuccess(response.message);
          setOpenRating(false);
          localStorage.removeItem("data_rating");
        } else if (response.status === 0) {
          alertFail(response.message);
        }
      } catch (error: any) {
        setStatusLoader(false);
        alertFail(error.response.data.message);
      }
    }
  };
  //   console.log(data_rating);
  const handleSetRating = (e: any) => {
    setRate(e);
    local.StorageSetSecure("data_rating", { ...data_rating, rate: e });
  };
  const handleSetReview = (e: any) => {
    local.StorageSetSecure("data_rating", { ...data_rating, rate, review: e.target.value });
  };

  const RatingModal = (open: boolean, onClose: () => void, userInfo: CalendarPartner) => {
    return (
      <Modal open={open} centered onCancel={onClose} footer={null} className="user-evaluate">
        {/* <div className="user-evaluate"> */}
        <div className="user-evaluate-header">
          {/* <span className="user-evaluate-title">Cảm ơn bạn đã đặt dịch vụ</span> */}
          {/* <button className="user-evaluate-close" onClick={onClose}>
              ✕
            </button> */}
        </div>
        <div className="user-evaluate-body">
          <div className="user-info">
            <div className="user-avatar">
              <img src={userInfo?.user?.avatar !== "" ? userInfo?.user?.avatar : icon_user} alt="" />
            </div>
            <span className="user-name">{userInfo?.user?.name}</span>
            <span className="user-description">{t("vui_long_danh_gia_khach_hang")}</span>
          </div>
          <Rate className="user-evaluate-rate" onChange={handleSetRating} />
          <TextArea rows={4} className="user-evaluate-review" onChange={handleSetReview}></TextArea>
          <Button className="user-evaluate-submit primary" onClick={handleRateUser}>
            {t("gui_danh_gia")}
          </Button>
        </div>
        {/* </div> */}
      </Modal>
    );
  };

  const listSchedule: TabsProps["items"] = [
    {
      key: "1",
      label: t("danh_sach_lich_hen"),
      children: (
        <div className="calendar-list">
          {allSchedule?.data?.schedules.map((item: CalendarPartner, index: number) => (
            <div className="calendar-item" key={index}>
              <div className="calendar-item-info">
                <div className="calendar-icon calendar-schedule">
                  <img src={icon_calendar} alt="" />
                </div>
                <Link
                  // to="/partner/calendar/detail"
                  to={paths.calendarPartnerDetail}
                  state={item}
                  className="calendar-info"
                >
                  <div className="calendar-detail">
                    <span className="calendar-date">
                      {t("ngay")} {dayjs(item?.day_working).format("DD-MM-YYYY")}
                    </span>
                    <table className="calendar-table">
                      <tbody>
                        <tr>
                          <td className="calendar-key">{t("cong_viec")}</td>
                          <td className="calendar-value">{item?.service}</td>
                        </tr>
                        <tr>
                          <td className="calendar-key">{t("gio_bat_dau")}</td>
                          <td className="calendar-value">{item?.time_start}</td>
                        </tr>
                        <tr>
                          <td className="calendar-key">{t("tong_tien")}</td>
                          <td className="calendar-value calendar-price">{formatPrice(item?.price)}</td>
                        </tr>
                        <tr>
                          <td className="calendar-key">{t("dia_chi")}</td>
                          <td className="calendar-value">{item?.address}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Link>
              </div>
              <div className="calendar-item-action">
                <a href={"tel:" + item?.user?.telephone} className="calendar-contact primary">
                  {t("lien_he")}
                </a>
                {item?.status === 0 ? (
                  <button className="calendar-confirm primary" onClick={() => handleStartJob(item?.id)}>
                    {t("bat_dau")}
                  </button>
                ) : (
                  // ) : item?.status === 1 ? (
                  //   <button className="calendar-confirm complete primary" onClick={() => handleFinishJob(item?.id)}>
                  //     Hoàn thành
                  //   </button>
                  // ) : (
                  <></>
                )}
              </div>
            </div>
          ))}
        </div>
      ),
    },
    {
      key: "2",
      label: t("danh_sach_da_hen"),
      children: (
        <div className="calendar-list">
          {allSchedule?.data?.old_schedules.map((item: CalendarPartner, index: number) => (
            <div className="calendar-item" key={index}>
              <div className="calendar-item-info">
                <div className="calendar-icon calendar-complete">
                  <img src={icon_calendar} alt="" />
                </div>
                <Link
                  // to="/partner/calendar/detail"
                  to={paths.calendarPartnerDetail}
                  state={item}
                  className="calendar-info"
                >
                  <div className="calendar-detail">
                    <span className="calendar-date">
                      {t("ngay")} {dayjs(item?.day_working).format("DD-MM-YYYY")}
                    </span>
                    <table className="calendar-table">
                      <tbody>
                        <tr>
                          <td className="calendar-key">{t("cong_viec")}</td>
                          <td className="calendar-value">{item?.service}</td>
                        </tr>
                        <tr>
                          <td className="calendar-key">{t("gio_bat_dau")}</td>
                          <td className="calendar-value">{item?.time_start}</td>
                        </tr>
                        <tr>
                          <td className="calendar-key">{t("tong_tien")}</td>
                          <td className="calendar-value calendar-price">{formatPrice(item?.price)}</td>
                        </tr>
                        <tr>
                          <td className="calendar-key">{t("dia_chi")}</td>
                          <td className="calendar-value">{item?.address}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Link>
              </div>
              <div className="calendar-item-action">
                <button
                  className="calendar-contact primary"
                  onClick={() => {
                    setOpenRating(true);
                    setSelectedUser(item);
                  }}
                >
                  {t("danh_gia")}
                </button>
              </div>
            </div>
          ))}
          {selectedUser &&
            RatingModal(
              openRating,
              () => {
                setOpenRating(false);
                setSelectedUser(undefined);
                setRate(0);
                localStorage.removeItem("data_rating");
              },
              selectedUser,
            )}
        </div>
      ),
    },
  ];
  return (
    <div className="wrapper my-calendar-partner">
      {showPopup}
      {statusLoader ? overLoader() : ""}
      {loadingSchedule && overLoader()}
      <HeaderSevice text={t("lich_cua_toi")} />
      <div className="calendar">
        <Tabs
          items={listSchedule}
          defaultActiveKey="1"
          className="calendar-tabs"
          onChange={(key) => {
            Number(key) === 2
              ? document.querySelector(".ant-tabs-ink-bar.ant-tabs-ink-bar-animated")?.classList.add("right")
              : document.querySelector(".ant-tabs-ink-bar.ant-tabs-ink-bar-animated")?.classList.remove("right");
          }}
        />
      </div>
      <div className="overlay"></div>
    </div>
  );
};
