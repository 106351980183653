import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getNotifyUser } from "../../../redux/Slices/userSlice";
import HeaderSevice from "../../../components/HeaderService";
import overLoader from "../../../components/overLoader";
import { NotifyUser } from "../../../types/common";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";

export const NotificationPartner = () => {
  //   const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const allNoti = useAppSelector((state) => state?.user?.notifiUser?.datas);
  const loading = useAppSelector((state) => state?.user?.loading);
  const { t, i18n } = useTranslation(["home"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  useEffect(() => {
    dispatch(getNotifyUser());
  }, [dispatch]);

  //   const handleNavigate = (item: NotifyUser) => {
  //     if (item.notification_type === 1) {
  //       navigate("/partner/notify/detail", { state: item });
  //     }
  //   };

  const renderNotiList = () => {
    return (
      allNoti?.length > 0 &&
      allNoti.map((item: NotifyUser, index: number) => (
        <div
          className="notification-item"
          key={index}
          //   onClick={() => handleNavigate(item)}
        >
          <div className="notification-item-icon">
            <img src={item?.image} alt="" />
          </div>
          <div className="notification-item-detail">
            <h1 className="notification-item-title">{item.title}</h1>
            <p className="notification-item-description">{item.description}</p>
            <span className="notification-item-time">{item.created_at}</span>
          </div>
        </div>
      ))
    );
  };

  return (
    <div className="wrapper notification">
      {loading && overLoader()}
      <HeaderSevice text={t("danh_sach_thong_bao")} />
      <div className="notification-list content-container">{renderNotiList()}</div>
    </div>
  );
};

export default Notification;
