import { Link, useNavigate } from "react-router-dom";

import image_logo_full from "../../../assets/images/image_logo_full.png";
import icon_back from "../../../assets/icons/icon_back.png";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";
import { useEffect } from "react";

export const Terms = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["setting"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  return (
    <div className="wrapper terms-of-use">
      <div className="header">
        <Link to="#" onClick={() => navigate(-1)}>
          <button className="header-back-button">
            <div className="header-back-icon">
              <img src={icon_back} alt="" />
            </div>
          </button>
        </Link>
        <span className="header-text">{t("dieu_khoan_su_dung")}</span>
        <div className="no-action" />
      </div>
      <div className="terms-of-use content-container">
        <div className="logo">
          <img src={image_logo_full} alt="" />
        </div>
        <h1 className="title">CÁC ĐIỀU KHOẢN CỦA THỎA THUẬN DỊCH VỤ</h1>
        <div className="text">
          <p>
            Thỏa thuận dịch vụ này (“Thỏa thuận”) cấu thành thỏa thuận pháp lý giữa Bạn, một Nhà cung cấp dịch vụ chăm
            sóc sức khỏe (“Bạn”) và Công ty cổ phần Bluecare (sau đây gọi tắt là “Bluecare” hoặc “chúng tôi”), một Công
            ty được thành lập và hoạt động theo luật pháp nhà nước Cộng hòa xã hội chủ nghĩa Việt Nam, số đăng ký doanh
            nghiệp, có địa chỉ tại tầng 9 tòa nhà Lotus số 2 Duy Tân, Cầu Giấy, Hà Nội.
          </p>
          <p>
            Bluecare cung cấp dịch vụ kết nối với khách hàng cho các Nhà cung cấp dịch vụ chăm sóc sức khỏe thông qua
            Nền tảng công nghệ Bluecare được định nghĩa dưới đây. Bạn muốn tham gia Thỏa thuận này nhằm mục đích truy
            cập và sử dụng Nền tảng công nghệ Bluecare. Bạn nhận thức đầy đủ và đồng ý rằng Bluecare là một Công ty cung
            cấp dịch vụ công nghệ IT, không cung cấp dịch vụ chăm sóc sức khỏe.
          </p>
          <p>
            Để sử dụng Nền tảng công nghệ Bluecare, Bạn phải đồng ý với các Điều khoản sử dụng dịch vụ (sau đây gọi là
            “Điều khoản”) dưới đây. Các Điều khoản này do Công ty cổ phân Bluecare đưa ra để điều chỉnh và áp dụng cho
            việc bạn truy cập và sử dụng website www.Bluecare.vn và các dịch vụ của Bluecare có trên trang web của
            Bluecare và các ứng dụng của Bluecare trên thiết bị di động (sau đây gọi chung là Nền tảng công nghệ
            Bluecare hoặc Nền tảng công nghệ). Qua việc truy cập và sử dụng Nền tảng công nghệ của chúng tôi, bạn đồng ý
            chịu sự ràng buộc bởi tất cả các Điều khoản được quy định tại đây. Nếu bạn không đồng ý với tất cả các Điều
            khoản này, đề nghị không sử dụng Nền tảng công nghệ của chúng tôi. Việc sử dụng nền tảng công nghệ đồng
            nghĩa với việc bạn đã chấp thuận tất cả các Điều khoản của Thỏa thuận và các Điều khoản của Thỏa thuận có
            thể được Bluecare sửa đổi tại bất kỳ thời điểm nào. Phiên bản Thỏa thuận mới nhất sẽ được cập nhật trên
            Internet tại địa chỉ www.Bluecare.vn/điều-khoản-sử-dụng.html. Bluecare giữ quyền thay đổi hoặc sửa đổi Thỏa
            thuận này hoặc bất kỳ chính sách nào khác của Bluecare liên quan tới việc sử dụng Nền tảng công nghệ tại bất
            kỳ thời điểm nào và tùy thuộc vào quyết định riêng của mình qua việc đăng các sửa đổi trên Internet tại địa
            chỉ www.Bluecare.vn. Việc tiếp tục sử dụng Nền tảng công nghệ sau khi các thay đổi hoặc sửa đổi được đăng
            trên Internet sẽ được xem là chấp thuận toàn bộ các thay đổi hoặc sửa đổi đó.
          </p>
          <p>
            Nền tảng công nghệ Bluecare cung cấp một môi trường nơi bạn hoạt động với tư cách là một nhà cung cấp dịch
            vụ chăm sóc sức khỏe, bao gồm nhưng không giới hạn ở: chăm sóc, chẩn đoán, kê đơn, tư vấn, điều trị tại chỗ,
            điều trị từ xa… (gọi chung là “Dịch vụ chăm sóc sức khỏe”) có thể kết nối với các bệnh nhân có nhu cầu về
            dịch vụ của bạn (“Bệnh nhân”). Ngoài ra, Nền tảng công nghệ cũng được sử dụng như một công cụ hỗ trợ bạn
            trong việc tổ chức, hiểu và quản lý các thông tin liên quan đến chăm sóc sức khỏe.
          </p>
          <p>
            LƯU Ý: BLUECARE KHÔNG PHẢI LÀ BÊN TIẾP NHẬN CÁC DỊCH VỤ CHĂM SÓC SỨC KHỎE CỦA BẠN VÀ KHÔNG CÓ LIÊN KẾT VỚI
            BẤT KỲ BỆNH NHÂN NÀO. DO ĐÓ, NỀN TẢNG CÔNG NGHỆ NÀY KHÔNG HƯỚNG TỚI (VÀ KHÔNG) TẠO RA BẤT CỨ MỐI QUAN HỆ BÁC
            SĨ – BỆNH NHÂN GIỮA BẠN VÀ BLUECARE. BẠN MỚI CÓ QUYỀN QUYẾT ĐỊNH VIỆC GỬI HAY KHÔNG GỬI BẢN CHÀO DỊCH VỤ
            CHĂM SÓC SỨC KHỎE TỚI BỆNH NHÂN VÀ NGƯỢC LẠI BỆNH NHÂN CŨNG CÓ TOÀN QUYỀN QUYẾT ĐỊNH CHẤP NHẬN HAY KHÔNG
            CHẤP NHẬN DỊCH VỤ CHĂM SÓC SỨC KHỎE CỦA BẠN ĐƯỢC KẾT NỐI THÔNG QUA NỀN TẢNG CÔNG NGHỆ CỦA BLUECARE. BẤT KỲ
            QUYẾT ĐỊNH DỊCH VỤ CHĂM SÓC SỨC KHỎE MÀ BẠN ĐƯA RA ĐỀU LÀ QUYẾT ĐỊNH CỦA RIÊNG BẠN. MỖI DỊCH VỤ CHĂM SÓC SỨC
            KHỎE BẠN CUNG CẤP CHO BỆNH NHÂN SẼ TẠO THÀNH MỘT THỎA THUẬN RIÊNG GIỮA BẠN VÀ BỆNH NHÂN ĐÓ.
          </p>
          <p>
            THÊM VÀO ĐÓ, BẠN HIỂU RÕ VÀ ĐỒNG Ý RẰNG KHI CUNG CẤP NỀN TẢNG CÔNG NGHỆ NÀY, CHÚNG TÔI DỰA TRÊN MỘT SỐ NHÀ
            CUNG CẤP THỨ BA, BAO GỒM CẢ NHỮNG MỤC ĐÍCH VỀ VIỆC GỬI CÁC THÔNG BÁO THÚC GIỤC, VÀ Ở ĐÂY CHÚNG TÔI TỪ CHỐI
            BẤT CỨ NGHĨA VỤ NÀO LIÊN QUAN TỚI CÁC DỊCH VỤ ĐƯỢC CUNG CẤP BỞI CÁC NHÀ CUNG CẤP THỨ BA ĐÓ.
          </p>
        </div>
        <h1 className="title">SỬ DỤNG NỀN TẢNG CÔNG NGHỆ</h1>
        <div className="text">
          <p>
            Bluecare cho phép bạn truy cập và sử dụng Nền tảng công nghệ thông qua các ứng dụng trên thiết bị di động và
            trang web của chúng tôi (sau đây gọi chung là “Ứng dụng”). Thông qua nền tảng công nghệ này, chúng tôi đề
            xuất các dịch vụ kết nối giữa bạn và những bệnh nhân cần dịch vụ chăm sóc sức khỏe của bạn.
          </p>
          <p>Nền tảng công nghệ này chỉ dành cho người ít nhất từ 18 tuổi trở lên.</p>
        </div>
        <div className="title">TẠO MỘT TÀI KHOẢN TRÊN BLUECARE</div>
        <div className="text">
          <p>
            Bạn cần đăng ký và tạo một tài khoản (“Tài khoản” của bạn) để sử dụng Nền tảng công nghệ Bluecare (vui lòng
            xem Chính sách bảo mật của chúng tôi để biết thêm chi tiết). Là một nhà cung cấp dịch vụ chăm sóc sức khoẻ,
            bạn cũng được yêu cầu phải tạo một tài khoản thanh toán (“Tài khoản thanh toán”) bằng cách cung cấp số tài
            khoản ngân hàng của Bạn.
          </p>
          <p>
            Bạn cần đảm bảo rằng bạn đủ độ tuổi hợp pháp để hình thành một hợp đồng ràng buộc và không phải là người bị
            pháp luật hạn chế việc sử dụng các dịch vụ theo luật pháp Việt Nam hoặc theo các hệ thống pháp luật hiện
            hành khác. Bạn đảm bảo rằng tất cả các thông tin cá nhân mà bạn cung cấp cho chúng tôi (ví dụ, thông qua
            việc đăng ký hoặc quá trình tạo tài khoản ứng dụng) là chính xác, và bạn sẽ không tạo bất kỳ tài khoản nào
            cho bất cứ ai khác ngoài bản thân mình mà không có sự cho phép của người đó.
          </p>
          <p>
            Bạn có thể tạo một tài khoản thuộc một trong các khả năng sau đây bằng cách xác nhận trong quá trình đăng ký
            tài khoản: (1) một bác sĩ, (2) một y sỹ, (3) một bệnh viện, phòng khám (4), Một điều dưỡng, (5), một kỹ
            thuật viên, cử nhân phục hồi chức năng, (6), một kỹ thuật viện xoa bóp bấm huyệt, (7), một hộ lý, (8) Một
            điều dưỡng viên chăm sóc sức khỏe.
          </p>
          <p>
            <b>Nếu bạn đăng ký một tài khoản là một bác sĩ, bạn cam kết và bảo đảm là:</b>
          </p>
          <ul>
            <li>Bạn có đăng ký kinh doanh hợp pháp để thực hiện hoạt động khám chữa bệnh.</li>
            <li>Bạn có chứng chỉ hành nghề do cơ quan nhà nước có thẩm quyền cấp.</li>
          </ul>
          <p>
            <b>Nếu bạn đăng ký một tài khoản là một y tá, điều dưỡng bạn cam kết và bảo đảm là:</b>
          </p>
          <ul>
            <li>Bạn có đăng ký kinh doanh hợp pháp để thực hiện hoạt động y tá.</li>
            <li>Bạn có chứng chỉ hành nghề do cơ quan nhà nước có thẩm quyền cấp.</li>
          </ul>
          <p>
            <b>Nếu bạn đăng ký một tài khoản là một bệnh viện, phòng khám, bạn cam kết và bảo đảm là:</b>
          </p>
          <ul>
            <li>Bạn được cơ quan nhà nước có thẩm quyền cấp phép hoạt động.</li>
            <li>Bạn có chứng chỉ hành nghề do cơ quan nhà nước có thẩm quyền cấp.</li>
          </ul>
          <p>
            <b>Nếu bạn đăng ký là một kỹ thuật viên, cử nhân phục hồi chức năng, bạn cam kết và bảo đảm là:</b>
          </p>
          <ul>
            <li>Bạn có đăng ký kinh doanh hợp pháp để thực hiện hoạt động phục hồi chức năng.</li>
            <li>Bạn có chứng chỉ hành nghề do cơ quan nhà nước có thẩm quyền cấp.</li>
          </ul>
          <p>
            <b>Nếu bạn đăng ký là một kỹ thuật viên xoa bóp bấm huyệt, bạn cam kết và bảo đảm là:</b>
          </p>
          <ul>
            <li>Bạn có đăng ký kinh doanh hợp pháp để thực hiện hoạt động xoa bóp bấm huyệt.</li>
            <li>Bạn có chứng chỉ hành nghề do cơ quan nhà nước có thẩm quyền cấp.</li>
          </ul>
          <p>
            <b>Nếu bạn đăng ký là một hộ lý, bạn cam kết và bảo đảm là:</b>
          </p>
          <ul>
            <li>Bạn có đăng ký kinh doanh hợp pháp để thực hiện hoạt động hộ lý.</li>
            <li>Bạn có chứng chỉ hành nghề do cơ quan nhà nước có thẩm quyền cấp.</li>
          </ul>
          <p>
            <b>Nếu bạn đăng ký là một điều dưỡng viên chăm sóc sức khỏe, bạn cam kết và bảo đảm là:</b>
          </p>
          <ul>
            <li>Bạn có đăng ký kinh doanh hợp pháp để thực hiện hoạt động điều dưỡng.</li>
            <li>Bạn có đang học năm từ năm thứ 2 trở nên ở khoa điều dưỡng của các trường cao đẳng hoặc đại học.</li>
            <li>
              Các bác sỹ, y tá, điều dưỡng, hộ lý, kỹ thuật viên, cử nhân được bạn cho phép truy cập, sử dụng Nền tảng
              công nghệ Bluecare qua tài khoản của bạn phải có chứng chỉ hành nghề hợp pháp.
            </li>
          </ul>
          <p>
            Chúng tôi bảo lưu quyền chặn hoặc khóa tài khoản của bạn nếu bất kỳ thông tin nào được cung cấp trong quá
            trình đăng ký hoặc sau đó chứng tỏ là không chính xác, sai sự thật, vi phạm các Điều khoản của chúng tôi,
            hoặc trong trường hợp bạn đã tạo ra nhiều hơn một tài khoản.Bạn chịu trách nhiệm cho việc duy trì tính bảo
            mật của mật khẩu và tài khoản của bạn, và đồng ý thông báo ngay cho chúng tôi nếu mật khẩu của bạn bị mất,
            bị đánh cắp, hoặc bị lộ cho một bên thứ ba trái phép nhằm tránh bị xâm nhập bất hợp pháp. Bạn hoàn toàn chịu
            trách nhiệm về các hoạt động diễn ra từ tài khoản của bạn.
          </p>
        </div>
        <h1 className="title">THANH TOÁN</h1>
        <div className="text">
          <p>
            Bất kỳ khoản phí nào mà Công ty có thể tính cho bạn khi bạn sử dụng Dịch vụ, sẽ đến hạn thanh toán ngay lập
            tức và không được hoàn lại (“Phí Dịch vụ”). Chính sách không hoàn lại này sẽ được áp dụng tại mọi thời điểm
            bất kể bạn quyết định chấm dứt sử dụng, hay chúng tôi quyết định chấm dứt hoặc đình chỉ việc sử dụng của
            bạn, hay sự gián đoạn gây ra cho Dịch vụ theo kế hoạch, ngẫu nhiên hoặc cố ý hoặc bất kỳ lý do gì.
          </p>
          <p>
            Là một nhà cung cấp dịch vụ chăm sóc sức khoẻ, bạn sẽ bị áp dụng Phí dịch vụ đối với Người cung cấp dịch vụ
            chăm sóc sức khoẻ (Phí sử dụng phần mềm của Bluecare, như đề cập dưới đây). Bluecare sẽ xử lý tất cả các
            khoản thanh toán của bạn hàng tuần thông qua bên xử lý thanh toán thứ ba. Khi được bạn lựa chọn, Bluecare sẽ
            ứng trước Phí dịch vụ chăm sóc sức khoẻ vào tài khoản thanh toán bạn thiết lập trong quá trình đăng ký. Bạn
            nhận thức đầy đủ và đồng ý rằng số tiền này không bao gồm bất kỳ khoản lãi nào và sẽ được trừ đi những khoản
            tiền mà chúng tôi được yêu cầu phải giữ lại theo quy định của pháp luật.
          </p>
          <p>
            Bluecare có quyền giữ lại toàn bộ hoặc một phần của Phí dịch vụ chăm sóc sức khỏe nếu tin rằng bạn đã cố
            gắng lừa gạt hoặc lạm dụng Bluecare hoặc các hệ thống thanh toán của Bluecare.
          </p>
          <p>
            Để đổi lại việc bạn được phép đề nghị cung cấp dịch vụ chăm sóc sức khoẻ cho khách hàng thông qua Nền tảng
            công nghệ Bluecare như một Nhà cung cấp dịch vụ chăm sóc sức khoẻ, bạn đồng ý trả cho Bluecare (và cho phép
            Bluecare giữ lại) một khoản phí dựa trên mỗi giao dịch mà bạn cung cấp dịch vụ chăm sóc sức khoẻ (các “Phí
            sử dụng phần mềm”). Tiền phí sử dụng phần mềm được áp dụng sẽ được thông báo cho bạn trong Bảng phí quản lý.
          </p>
          <p>
            BẠN THỪA NHẬN RẰNG TỔNG SỐ TIỀN PHÍ MÀ BỆNH NHÂN HOẶC KHÁCH HÀNG TRẢ CHO BẠN ĐÃ BAO GỒM CẢ PHÍ SỬ DỤNG PHẦN
            MỀM MÀ BẠN ĐANG THU HỘ CÔNG TY. PHÍ SỬ DỤNG PHẦN MỀM ĐÓ CÓ THỂ LÊN ĐẾN 25% PHÍ ĐƯỢC QUY ĐỊNH CHO DỊCH VỤ CHO
            MỖI LẦN BỆNH NHÂN HOẶC KHÁCH HÀNG HOÀN TẤT MỘT DỊCH VỤ, SẼ DO CÔNG TY TOÀN QUYỀN QUYẾT ĐỊNH, TÙY TỪNG THỜI
            ĐIỂM.
          </p>
          <p>
            Bluecare có thể, theo toàn quyền quyết định của mình, đưa ra các ưu đãi khuyến mại với các đặc tính khác
            nhau và tỷ lệ khác nhau cho bất kỳ Bệnh nhân hoặc Khách hàng nào, theo đó các ưu đãi khuyến mại này sẽ do
            bạn thực hiện.
          </p>
          <p>
            Bluecare có thể xác định hoặc thay đổi Phí Dịch vụ khi Công ty, theo toàn quyền quyết định của mình, cho là
            cần thiết hoặc phù hợp với hoạt động kinh doanh.
          </p>
        </div>
        <h1 className="title">THANH TOÁN CỦA BỆNH NHÂN HOẶC KHÁCH HÀNG</h1>
        <div className="text">
          <p>
            Bệnh nhân hoặc Khách hàng có thể chọn thanh toán cho Dịch vụ bằng tiền mặt và trong trường hợp áp dụng, bằng
            chuyển khoản, bằng thẻ tín dụng hoặc thẻ ghi nợ (“Thẻ”), ví điện tử. Trong trường hợp Bệnh nhân hoặc Khách
            hàng chọn thanh toán cho Dịch vụ bằng Thẻ, tất cả các khoản thanh toán phải trả cho bạn, bao gồm tiền boa
            (trong trường hợp áp dụng), cho Dịch vụ sẽ được chuyển đến cho bạn theo con số đã thỏa thuận. Mọi khiếu nại
            mà Bệnh nhân hoặc Khách hàng có thể đưa ra liên quan đến dịch vụ vận tải do bạn cung cấp sẽ được giải quyết
            trực tiếp giữa Bệnh nhân hoặc Khách hàng và bạn.
          </p>
          <p>
            Công ty bảo lưu quyền tạm ngừng xử lý bất kỳ giao dịch nào mà công ty tin tưởng hợp lý rằng giao dịch đó có
            thể là gian lận, bất hợp pháp hoặc liên quan đến bất kỳ hoạt động tội phạm nào hoặc trong trường hợp công ty
            tin tưởng hợp lý rằng Bệnh nhân hoặc Khách hàng vi phạm Các Điều khoản và Điều kiện giữa Bệnh nhân hoặc
            Khách hàng và Công ty. Trong trường hợp đó, bạn sẽ không được yêu cầu Công ty phải chịu trách nhiệm về bất
            kỳ khoản khấu trừ, chậm trễ, tạm ngừng hoặc hủy bỏ đối với bất kỳ khoản thanh toán nào cho bạn.
          </p>
          <p>
            Bạn đồng ý rằng bạn sẽ hợp tác trong bất kỳ cuộc điều tra tội phạm nào khi được yêu cầu và đồng ý hỗ trợ
            Công ty tuân thủ bất kỳ cuộc điều tra nội bộ, hay bất kỳ chỉ đạo nào từ cơ quan chức năng hoặc các yêu cầu
            của luật hiện hành hoặc các quy định áp dụng.
          </p>
        </div>
        <h1 className="title">CHÍNH SÁCH HỦY LỊCH</h1>
        <div className="text">
          <p>
            Khách hàng cần sự giúp đỡ của Bạn cho nhu cầu chăm sóc sức khỏe của họ. Bạn đồng ý rằng tỷ lệ hủy lịch cao
            và/hoặc thường xuyên hoặc bỏ qua lệnh đặt lịch của Khách hàng sẽ làm giảm trải nghiệm được chăm sóc sức khỏe
            của Khách hàng; nó cũng sẽ tác động tiêu cực đến uy tín và thương hiệu của Công ty.
          </p>
          <p>
            Mặc dù bạn có thể hủy lịch, việc hủy phải dựa trên những lý do được chấp nhận như quy định tại Đơn đăng ký.
            Việc hủy lịch không dựa trên một trong những lý do được chấp nhận hoặc bỏ qua lệnh đặt lịch (được gọi chung
            là “Huỷ Lịch“) sẽ được xem xét trong việc quyết định quyền truy cập của bạn vào Ứng dụng hoặc Mạng lưới sẽ
            bị hạn chế tạm thời (“Thời Gian Chờ”).
          </p>
          <p>
            Theo Chính Sách Huỷ Lịch này, bạn sẽ có thời gian chờ tối thiểu là 72 giờ nếu bạn huỷ lịch 3 lần liên tiếp.
            Số lần Huỷ Lịch càng nhiều Thời Gian Chờ sẽ càng lâu hơn.
          </p>
          <ul>
            <li>
              Bộ đếm Huỷ Lịch sẽ trở về 0 nếu bạn không nhận được bất kỳ lệnh đặt xe nào trong khoảng thời gian 72 giờ.
            </li>
            <li>Bộ đếm Huỷ Lịch sẽ trở về 0 ngay khi bạn hoàn thành một Lịch đặt.</li>
            <li>Bỏ qua một lệnh đặt lịch sẽ được tính là một lần Huỷ Lịch.</li>
            <li>Chỉ Hủy Lịch trong các lý do được chấp nhận sẽ không được tính đến theo chính sách này.</li>
            <li>
              Huỷ Lịch bởi Khách hàng trước khi đã cung cấp ca dịch vụ đầu tiên sẽ không được xem xét theo chính sách
              này.
            </li>
            <li>
              Huỷ Lịch bởi Khách hàng sau khi hoàn thành cung cấp ca dịch vụ đầu tiên sẽ được xem xét theo chính sách
              này.
            </li>
            <li>
              Huỷ Lịch được thực hiện khi bạn đang quá cảnh hoặc trong quá trình hoàn tất đặt chỗ sẽ không được xem xét
              theo chính sách này.
            </li>
          </ul>
          <p>
            Công ty bảo lưu quyền thay đổi bất kỳ điều kiện nào trên đây tùy theo quyết định riêng của mình mà không cần
            thông báo thêm cho bạn.
          </p>
        </div>
        <h1 className="title">THUẾ</h1>
        <div className="text">
          <p>
            Bạn đồng ý rằng Hợp đồng này sẽ phải chịu tất cả các khoản thuế, thuế quan, phí, lệ phí và/hoặc chi phí theo
            quy định của pháp luật hiện hành, cho dù được ấn định dưới bất kỳ hình thức nào, có hiệu lực áp dụng và liên
            quan đến bất kỳ loại thuế nào có thể được áp dụng tại bất kỳ thời điểm nào trong tương lai. Bạn cũng đồng ý
            thêm rằng bạn sẽ nỗ lực tối đa để thực hiện mọi yêu cầu cần thiết hoặc theo quy định của pháp luật liên quan
            để tạo điều kiện, hỗ trợ và/hoặc bảo vệ Công ty trong việc yêu cầu hoặc xác minh bất kỳ khoản khấu trừ thuế
            đầu vào, khoản bù trừ, giảm giá hoặc hoàn lại của bất kỳ khoản thuế đã nộp hoặc phải nộp nào liên quan đến
            Dịch vụ được cung cấp theo Hợp đồng này.
          </p>
        </div>
        <h1 className="title">TRANH CHẤP THANH TOÁN VÀ HOÀN LẠI TIỀN</h1>
        <div className="text">
          <p>
            Mỗi bệnh nhân có quyền nộp đơn yêu cầu giải quyết tranh chấp (“Tranh chấp thanh toán”) trong vòng 180 ngày
            kể từ ngày các dịch vụ chăm sóc sức khỏe bạn cung cấp được thực hiện xong. Bạn ủy quyền cho Bluecare là
            Người trung gian hòa giải đứng ra dàn xếp bất kỳ tranh chấp thanh toán nào giữa bạn với bệnh nhân.
          </p>
        </div>
        <h1 className="title">CẤP PHÉP VÀ CÁC HẠN CHẾ</h1>
        <div className="text">
          <p>
            Với điều kiện bạn tuân thủ các Điều khoản này, bạn có quyền tải về và cài đặt Ứng dụng vào thiết bị di động
            hoặc máy tính của bạn để truy cập sử dụng Nền tảng công nghệ Bluecare, chỉ duy nhất cho mục đích sử dụng cá
            nhân. Bạn không được: (i) sao chép, sửa đổi hoặc phân phối ứng dụng cho các mục đích khác; (ii) chuyển
            nhượng, cấp phép lại, cho thuê, cho mượn hoặc phân phối ứng dụng cho bất kỳ bên thứ ba nào khác; (iii) sửa
            mã nguồn, thay đổi về mặt kỹ thuật, tháo gỡ, hoặc tạo ra các sản phẩm phái sinh của ứng dụng; (iv) tự ý sửa
            đổi tính năng của Ứng dụng hoặc Nền tảng công nghệ để tài khoản của bạn có thể cho nhiều người khác cùng sử
            dụng thông qua bất kỳ hình thức nào; hoặc (v) sử dụng Nền tảng công nghệ trái pháp luật dưới bất kỳ hình
            thức nào, cho bất kỳ mục đích trái pháp luật nào hoặc theo bất kỳ cách thức nào đó trái với các Điều khoản
            này.
          </p>
          <p>
            Các Điều khoản dưới đây được áp dụng cho bất kỳ phiên bản Ứng dụng được lấy hoặc tải về từ bất kỳ Kho ứng
            dụng nào của các Nhà phân phối ứng dụng (ví dụ: Apple® App Store hoặc Google Play®) nơi mà Ứng dụng có thể
            ngay bây giờ hoặc trong tương lai được cung cấp (“Nhà phân phối ứng dụng”). Bạn thừa nhận và đồng ý rằng:
          </p>
          <ul>
            <li>
              Những Điều khoản này được ký kết giữa bạn và Bluecare, không phải với Nhà phân phối ứng dụng, và Bluecare
              (không phải Nhà phân phối ứng dụng) tự chịu trách nhiệm cho ứng dụng của mình.
            </li>
            <li>Nhà phân phối ứng dụng không có nghĩa vụ phải cung cấp dịch vụ bảo trì và hỗ trợ đối với Ứng dụng.</li>
            <li>
              Trong trường hợp Ứng dụng không phù hợp với bất kỳ hình thức bảo hành nào được áp dụng, bạn có thể thông
              báo cho Nhà cung phân phối, và Nhà phân phối ứng dụng sẽ hoàn trả lại số tiền mua Ứng dụng cho bạn (nếu
              có) và đến mức bồi thường tối đa cho phép của pháp luật hiện hành, các nhà phân phối ứng dụng sẽ không có
              nghĩa vụ bảo hành nào khác đối với Ứng dụng. Bất kỳ khiếu nại, mất mát, thiệt hại, chi phí hoặc phí khác
              phát sinh do không thực hiện được bất kỳ hình thức bảo hành nào sẽ là trách nhiệm duy nhất của Bluecare.
            </li>
            <li>
              Nhà phân phối ứng dụng không chịu trách nhiệm giải quyết bất kỳ khiếu nại nào của bạn hoặc bất kỳ khiếu
              nại của bên thứ ba nào khác liên quan đến Ứng dụng hoặc sở hữu và sử dụng Ứng dụng của bạn, bao gồm, nhưng
              không giới hạn ở: (i) khiếu nại trách nhiệm sản phẩm; (ii) bất kỳ khiếu nại về việc Ứng dụng không phù hợp
              với quy định của pháp luật hiện hành; và (iii) khiếu nại phát sinh theo luật bảo vệ người tiêu dùng hoặc
              các quy định pháp luật tương tự.
            </li>
            <li>
              Trong trong hợp có bất kỳ khiếu nại nào của bên thứ ba về Ứng dụng hoặc việc chiếm hữu, sử dụng Ứng dụng
              của bạn vi phạm quyền sở hữu trí tuệ của Bên thứ ba, Bluecare sẽ chịu trách nhiệm toàn bộ cho việc điều
              tra, bảo vệ, giải quyết và bác bỏ các kiếu nại vi phạm sở hữu trí tuệ đó trong phạm vi yêu cầu của các
              Điều khoản này.
            </li>
            <li>
              Nhà phân phối ứng dụng và các phân nhánh của nó là bên thứ ba được hưởng lợi từ Điều khoản này do Bạn được
              cấp quyền sử dụng Ứng dụng và khi bạn chấp nhận các Điều khoản và điều kiện của các Điều khoản này, Nhà
              phân phối ứng dụng sẽ có quyền (và được coi là đã chấp nhận quyền) ràng buộc bạn thực thi các Điều khoản
              này liên quan tới việc Bạn được cấp quyền sử dụng Ứng dụng như một bên hưởng lợi thứ ba của Ứng dụng đó.
            </li>
            <li>
              NBạn cũng phải tuân thủ tất cả các Điều khoản dịch vụ được áp dụng của Bên thứ ba khi sử dụng Ứng dụng.
            </li>
          </ul>
        </div>
        <h1 className="title">SỞ HỮU TRÍ TUỆ</h1>
        <div className="text">
          <p>
            Bạn nhận thức rõ rằng không có bất kỳ quyền đối với các bằng sáng chế, bản quyền, nhãn hiệu trong hoặc liên
            quan tới Nền tảng công nghệ (gọi chung là Tài sản trí tuệ của Bluecare”) được chuyển giao cho bạn. Bạn cũng
            nhận thức rõ rằng quyền chiếm hữu và quyền sở hữu toàn diện đối với Nền tảng công nghệ sẽ vẫn là tài sản độc
            quyền của Bluecare và / hoặc các nhà cung cấp, và bạn sẽ không đòi hỏi bất kỳ quyền hạn nào đối với Nền tảng
            công nghệ này, trừ khi được đề cập rõ ràng ở trên. Tất cả bản quyền và tài sản trí tuệ của Bluecare trong và
            đối với Nền tảng công nghệ này (bao gồm nhưng không giới hạn ở: mọi logo, tên gọi, hình ảnh, tranh ảnh, hoạt
            ảnh, video, âm thanh, âm nhạc, văn bản và “giao diện” được tích hợp vào Nền tảng công nghệ), các chất liệu
            in ấn kèm theo và các bản sao của Ứng dụng, được sở hữu bởi Bluecare hoặc các nhà cung cấp thứ ba liên quan,
            ngoại trừ trường hợp cha mẹ hoặc người giám hộ hợp pháp của trẻ em dưới độ tuổi pháp lý để có thể tự mình
            tham gia giao dịch tạo tài khoản.
          </p>
          <p>
            Ứng dụng và Nền tảng công nghệ này được bảo vệ bởi luật bản quyền, điều ước quốc tế về bản quyền, các luật
            và điều ước về sở hữu trí tuệ khác.
          </p>
        </div>
        <h1 className="title">THAY ĐỔI VÀ QUẢNG CÁO</h1>
        <div className="text">
          <p>
            Nền tảng công nghệ của chúng tôi có thể thay đổi theo thời gian và/hoặc chúng tôi có thể ngừng (vĩnh viễn
            hoặc tạm thời) cung cấp Nền tảng công nghệ (hay các tính năng trong Nền tảng công nghệ này) mà không cần
            thông báo trước cho bạn. Nền tảng công nghệ của chúng tôi có thể bao gồm những quảng cáo mà có thể nhắm đến
            nội dung hoặc thông tin trên Nền tảng công nghệ, các truy vấn được thực hiện thông qua Nền tảng công nghệ
            này hoặc từ các thông tin khác. Loại hình và phạm vi quảng cáo trên Nền tảng công nghệ cũng có thể thay đổi
            theo thời gian. Khi cân nhắc về việc cung cấp cho bạn Nền tảng công nghệ, bạn nhất trí rằng chúng tôi và các
            nhà cung cấp thứ ba và các đối tác khác của chúng tôi có thể đặt quảng cáo trên Nền tảng công nghệ hoặc kết
            nối tới hiển thị nội dung hoặc thông tin trên Nền tảng công nghệ, và theo đó chúng tôi có thể nhận được thù
            lao từ việc đặt quảng cáo như vậy.
          </p>
        </div>
        <h1 className="title">ĐĂNG TẢI NỘI DUNG</h1>
        <div className="text">
          <p>
            Nền tảng công nghệ của chúng tôi cho phép bạn và những người dùng khác đăng tải, liên kết, lưu trữ, chia sẻ
            và các cách thức khác để tạo ra những thông tin, hình ảnh, videos, văn bản và/hoặc những nội dung khác (sau
            đây gọi là “Nội dung”). Bạn phải chịu trách nhiệm cho những nội dung mà bạn đăng lên Nền tảng công nghệ của
            chúng tôi, bao gồm cả tính hợp pháp, độ tin cậy và sự phù hợp của nó. Bằng việc đưa nội dung lên Nền tảng
            công nghệ, bạn cho phép chúng tôi được quyền và cấp phép cho sử dụng, sửa đổi, trình diễn, hiển thị công
            khai, tái sản xuất, bán lại và phân phối các nội dung này trên và thông qua Nền tảng công nghệ. Bạn đồng ý
            rằng việc cấp phép này bao gồm cả trao cho chúng tôi quyền được làm cho nội dung của bạn hiển thị phục vụ
            những người dùng khác của Nền tảng công nghệ, những người này có thể sử dụng nội dung của bạn trong phạm vi
            tuân thủ các Điều khoản này. Bạn có thể giữ lại bất kỳ và toàn bộ các quyền hạn đối với những nội dung bạn
            gửi, đăng tải, hiển thị trên hoặc thông qua Nền tảng công nghệ và bạn có trách nhiệm bảo vệ các quyền đó.
          </p>
          <p>
            Bạn có thể gỡ bỏ các nội dung mà bạn đã đăng tải bằng cách xóa chúng đi một cách cụ thể. Tuy nhiên, trong
            những trường hợp nhất định, một số Nội dung (như bài viết hoặc ý kiến của bạn) có thể không được xóa bỏ hoàn
            toàn và bản sao của những Nội dung của bạn có thể vẫn tiếp tục tồn tại trên Nền tảng công nghệ của chúng tôi
            và/hoặc ở nơi khác. Chúng tôi không chịu trách nhiệm cho việc gỡ bỏ hoặc xóa (hoặc việc gỡ bỏ hoặc xóa không
            thành công) bất kỳ nội dung nào trên Nền tảng công nghệ.Bạn cam đoan và bảo đảm rằng: (i) Nội dung là của
            bạn (bạn sở hữu nó), hoặc bạn có quyền sử dụng nó và trao cho chúng tôi các quyền và giấy phép như quy định
            tại các Điều khoản này, và (ii) Nội dung mà bạn đã đăng tải trên hoặc thông qua nền tảng của chúng tôi không
            vi phạm quyền riêng tư, quyền công khai, quyền tác giả, quyền hợp đồng hoặc bất kỳ quyền nào của bất cứ
            người nào khác.
          </p>
          <p>
            Chúng tôi yêu cầu Bạn tôn trọng Nền tảng công nghệ của chúng tôi và các Bên thứ ba khi đăng tải Nội dung và
            sử dụng Nền tảng công nghệ. Khi đưa Nội dung lên hoặc sử dụng Nền tảng công nghệ, bạn đồng ý sẽ không:
          </p>
          <ul>
            <li>
              Đưa lên các tài liệu vi phạm quyền sở hữu của bên thứ ba, bao gồm cả các quyền riêng tư, quyền công khai
              hoặc vi phạm bất kỳ luật hiện hành nào khác;
            </li>
            <li>
              Gửi các tài liệu trái pháp luật, tục tĩu, nói xấu, bôi nhọ, đe dọa, khiêu dâm, quấy rối, ghen ghét, phân
              biệt chủng tộc, chống đối chính quyền hoặc cổ động các hành vi được coi là vi phạm pháp luật hình sự, làm
              phát sinh trách nhiệm dân sự, vi phạm hoặc không phù hợp với pháp luật nói chung;
            </li>
            <li>
              Mạo danh người khác hoặc tự nhận là đại diện của chúng tôi, nhân viên của chúng tôi hoặc các chuyên gia
              khác trong ngành; hoặc thu lượm tên đăng nhập, địa chỉ hoặc địa chỉ email của người dùng cho các mục đích
              khác.
            </li>
          </ul>
          <p>
            Danh sách trên chỉ là một vài ví dụ và không được chỉ định là đã bao gồm đầy đủ các trường hợp và là duy
            nhất. Chúng tôi không có nghĩa vụ giám sát việc bạn truy cập hoặc sử dụng Nền tảng công nghệ hoặc kiểm tra
            hay chỉnh sửa Nội dung bất kỳ mà bạn đăng tải, nhưng chúng tôi có quyền làm như vậy vì mục đích vận hành Nền
            tảng công nghệ, hoặc để đảm bảo việc bạn tuân thủ các Điều khoản, tuân thủ pháp luật hiện hành hoặc theo yêu
            cầu của toà án, cơ quan hành chính, hoặc các cơ quan chính phủ khác. Chúng tôi bảo lưu quyền, bất cứ lúc nào
            và không cần báo trước, loại bỏ hoặc vô hiệu hóa quyền truy cập vào bất kỳ Nội dung nào mà chúng tôi, tùy
            theo quyết định riêng của mình, xét thấy có sự vi phạm các Điều khoản này hoặc gây nguy hại tới Nền tảng
            công nghệ.
          </p>
          <p>
            Chúng tôi cũng bảo lưu quyền đình chỉ hoặc chấm dứt Tài khoản của bạn và việc bạn sử dụng Nền tảng công nghệ
            của chúng tôi bất kỳ lúc nào trong trường hợp bạn vi phạm các Điều khoản hoặc trong trường hợp Bluecare
            ngừng cung cấp dịch vụ Nền tảng công nghệ này vì bất kỳ lý do gì.
          </p>
        </div>
        <h1 className="title">NỘI DUNG CỦA BÊN THỨ BA</h1>
        <div className="text">
          <p>
            Thông qua Nền tảng công nghệ, chúng tôi có thể cung cấp cho bạn quyền truy cập vào nội dung của Bên thứ ba,
            chẳng hạn như thông tin liên quan đến tương tác với các loại thuốc và thực phẩm chức năng, các bài viết mới
            và các nội dung khác. Bluecare ở đây từ chối bất kỳ nghĩa vụ nào liên quan tới bất cứ nội dung thông tin của
            Bên thứ ba nào như vậy. Không giới hạn bởi các điều kể trên, bạn hiểu và đồng ý rằng các nội dung của Bên
            thứ ba như vậy chỉ nhằm mục đích thông tin mà thôi. Tình hình sức khỏe cá nhân là việc của riêng bạn, và các
            nội dung của Bên thứ ba có thể không phù hợp hoặc không có liên quan với tình trạng sức khỏe riêng của bạn.
            Như đã nói rõ ở trên, Nền tảng công nghệ không chủ định cung cấp tư vấn về chăm sóc sức khỏe hoặc điều trị y
            tế, và trước khi có bất cứ quyết định gì có thể ảnh hưởng tới sức khỏe của bạn, bạn cần hỏi tư vấn của một
            chuyên gia y tế.
          </p>
        </div>
        <h1 className="title">ỦY QUYỀN</h1>
        <div className="text">
          <p>
            Bạn cho phép bất cứ việc sử dụng hoặc tiết lộ các thông tin sức khỏe được bảo mật mà không vì mục đích điều
            trị, thanh toán hay chăm sóc y tế. Việc ủy quyền này là dành cho sử dụng và tiết lộ thông tin y tế được bảo
            mật bởi Bluecare hoặc bởi một Bên thứ ba. Một danh sách không hạn chế các ví dụ như vậy bao gồm các tiết lộ
            thông tin cho một nhà cung cấp bảo hiểm nhân thọ vì mục đích bảo hiểm, tiết lộ cho một nhà tuyển dụng các
            kết quả kiểm tra sức khỏe hoặc các xét nghiệm y tế trước khi tuyển dụng, hoặc tiết lộ cho một công ty dược
            phẩm vì các mục đích tiếp thị riêng của họ. Tại thời điểm bạn yêu cầu chúng tôi tiết lộ các thông tin sức
            khỏe được bảo mật như vậy cho một Bên thứ ba, chúng tôi sẽ cung cấp cho bạn các thông tin cụ thể liên quan
            đến các thông tin y tế cần được tiết lộ hoặc sử dụng, người tiết lộ và nhận thông tin, thời hạn, quyền thu
            hồi bằng văn bản và dữ liệu khác.
          </p>
        </div>
        <h1 className="title">CÁC HÀNH VI KHÔNG ĐƯỢC PHÉP</h1>
        <div className="text">
          <p>
            Nền tảng công nghệ của chúng tôi chỉ có thể được sử dụng và truy cập cho các mục đích hợp pháp. Bạn đồng ý
            rằng bạn sẽ không làm bất cứ điều nào sau đây khi sử dụng hoặc truy cập vào Nền tảng công nghệ: (i) cố gắng
            để truy cập hoặc tìm kiếm Nền tảng hoặc tải nội dung từ các dịch vụ thông qua việc sử dụng bất kỳ công cụ,
            phần mềm, công cụ, đại lý, thiết bị hoặc cơ chế (bao gồm nhện, robot, trình thu thập, công cụ khai thác dữ
            liệu hoặc tương tự) nằm ngoài khác với các phần mềm và/hoặc tìm kiếm các đại lý cung cấp bởi chúng tôi hay
            nói chung là có sẵn trình duyệt web của bên thứ ba khác; (ii) tiếp cận, làm xáo trộn, hoặc sử dụng các khu
            vực ngoài công lập của Nền tảng, hệ thống máy tính của chúng tôi, hoặc hệ thống cung cấp kỹ thuật của các
            nhà cung cấp của chúng tôi; (iii) thu thập và sử dụng thông tin có sẵn thông qua Nền tảng, chẳng hạn như tên
            của người dùng khác, tên thật, địa chỉ email hoặc truyền đi bất cứ quảng cáo không mong muốn, thư rác, thư
            rác hoặc các hình thức chào mời; (iv) sử dụng Nền tảng cho bất kỳ mục đích thương mại hoặc vì lợi ích của
            bất kỳ bên thứ ba hoặc bằng bất cứ cách không phải bởi những Điều khoản này; (v) vi phạm bất kỳ luật hoặc
            quy định hiện hành; hoặc (vi) khuyến khích hoặc cho phép bất kỳ cá nhân nào khác làm bất cứ việc nào nói
            trên. Chúng tôi bảo lưu quyền để điều tra và truy tố các hành vi vi phạm của bất kỳ hành vi nào ở trên
            và/hoặc liên quan đến và hợp tác với các cơ quan thực thi pháp luật trong việc truy tố những người dùng vi
            phạm các Điều khoản này.
          </p>
        </div>
        <h1 className="title">KHÔNG CÓ QUAN HỆ ĐẠI LÝ</h1>
        <div className="text">
          <p>
            Bạn và Bluecare là các nhà cung cấp độc lập và không có quan hệ đại lý, hợp tác, liên doanh, quan hệ người
            lao động – sử dụng lao động hoặc bên nhượng quyền – bên nhận quyền nào được hướng tới hoặc tạo ra từ thỏa
            thuận này.
          </p>
        </div>
        <h1 className="title">BỒI THƯỜNG</h1>
        <div className="text">
          <p>
            Bạn đồng ý bảo vệ, bồi thường và giữ cho chúng tôi cũng như những chi nhánh của chúng tôi và từng lãnh đạo,
            giám đốc, nhân viên, đại lý, các cổ đông của chúng tôi được vô hại từ và trước bất kỳ các khiếu nại, hành
            động, kiện tụng, tổn thất, mất mát, thiệt hại, trách nhiệm, và chi phí (bao gồm cả phí luật sư) liên quan
            tới hoặc phát sinh từ việc bạn sử dụng Nền tảng công nghệ hoặc bất kỳ người nào khác dùng Tài khoản của bạn
            để sử dụng Nền tảng công nghệ, bao gồm nhưng không giới hạn ở:
          </p>
          <ul>
            <li>
              Bất kỳ cáo buộc về sơ suất phát sinh từ dịch vụ bạn cung cấp cho những bệnh nhân được kết nối với bạn
              thông qua Nền tảng công nghệ của chúng tôi;
            </li>
            <li>Bất kỳ vi phạm luật hoặc quy định hiện hành, hoặc các quyền của bất kỳ bên thứ ba nào</li>
            <li>
              Bất kỳ cáo buộc nào về bất kỳ tài liệu mà bạn gửi tới Nền tảng công nghệ vi phạm hoặc xâm phạm đến bản
              quyền, nhãn hiệu, bí mật thương mại hoặc tài sản trí tuệ khác của bất kỳ bên thứ ba nào.
            </li>
          </ul>
        </div>
        <h1 className="title">GIẢI PHÓNG TRÁCH NHIỆM</h1>
        <div className="text">
          <p>
            Trong trường hợp bạn có tranh chấp với một Nhà cung cấp dịch vụ y tế, bạn đồng ý giải phóng Bluecare (bao
            gồm cả các chi nhánh, giám đốc, nhân viên, người lao động, các đại lý, cổ đông, nhà cung cấp của chúng tôi)
            khỏi các khiếu nại, yêu cầu bồi thường thiệt hại thuộc bất kỳ loại hay bản chất nào, được biết tới hoặc chưa
            biết tới, bị nghi ngờ và không bị nghi ngờ, được tiết lộ và chưa được tiết lộ, phát sinh từ hoặc trong bất
            kỳ phương thức liên hệ nào tới các tranh chấp hoặc từ việc bạn sử dụng các nền tảng công nghệ của Bluecare.
            Hơn nữa, bạn công khai từ bỏ bất kỳ quyền nào bạn có theo Luật Dân sự Mục 1542 của Bang California (hoặc các
            luật tương tự của các Bang khác), trong đó quy định rằng: “Một sự giải phóng trách nhiệm nói chung không mở
            rộng đối với các khiếu nại mà bên thụ hưởng không biết hoặc không nghi sự ngờ tồn tại trong lợi ích của mình
            tại thời điểm thực hiện việc giải phóng nghĩa vụ, mà nếu anh ta được biết thì chắc chắn đã ảnh hưởng rất lớn
            tới quyết định của anh ta đối với bên có nghĩa vụ”
          </p>
        </div>
        <h1 className="title">THÔNG BÁO</h1>
        <div className="text">
          <p>
            Công ty có thể gửi thông báo dưới dạng một thông báo chung trên Ứng dụng, qua thư điện tử đến địa chỉ thư
            điện tử của bạn trong hồ sơ của Công ty, hoặc bằng văn bản gửi bằng thư Bảo đảm hoặc thư trả trước đến địa
            chỉ của bạn trong hồ sơ của Công ty. Thông báo đó sẽ được coi là đã gửi tới bạn sau 48 giờ kể từ lúc gửi bưu
            điện (nếu được gửi bằng thư Bảo đảm hoặc thư trả trước) hoặc 1 giờ sau khi gửi (nếu gửi bằng thư điện tử).
            Bạn có thể gửi thông báo cho Công ty (thông báo này sẽ được coi là đã được gửi khi Công ty nhận được thông
            báo) bằng thư gửi qua bưu điện hoặc thư bảo đảm tới Công ty theo các thông tin chi tiết liên hệ như được
            cung cấp trong Ứng dụng.
          </p>
        </div>
        <h1 className="title">CHUYỂN NHƯỢNG</h1>
        <div className="text">
          <p>
            Hợp đồng này, được tạo thành bởi các điều khoản và điều kiện được thay đổi tại từng thời điểm, sẽ không được
            bạn chuyển nhượng mà không có sự chấp thuận trước bằng văn bản của Công ty nhưng có thể được Công ty chuyển
            nhượng mà không cần sự đồng ý của bạn. Bất kỳ hành động cố ý chuyển nhượng nào của bạn vi phạm mục này sẽ bị
            coi là vô hiệu.
          </p>
        </div>
        <h1 className="title">CHẬM TRỄ DO INTERNET</h1>
        <div className="text">
          <p>
            DỊCH VỤ, ỨNG DỤNG VÀ/HOẶC PHẦN MỀM CÓ THỂ CÓ NHỮNG HẠN CHẾ, CHẬM TRỄ, VÀ CÁC VẤN ĐỀ KHÁC PHÁT SINH TỪ VIỆC
            SỬ DỤNG INTERNET VÀ THÔNG TIN LIÊN LẠC THEO PHƯƠNG THỨC ĐIỆN TỬ BAO GỒM VIỆC THIẾT BỊ BẠN SỬ DỤNG BỊ LỖI,
            KHÔNG CÓ KẾT NỐI, NẰM NGOÀI VÒNG PHỦ SÓNG, BỊ TẮT NGUỒN HOẶC KHÔNG HOẠT ĐỘNG. CÔNG TY KHÔNG CHỊU TRÁCH NHIỆM
            VỀ BẤT KỲ SỰ CHẬM TRỄ, LỖI CHUYỂN GIAO, CÁC THIỆT HẠI HAY TỔN THẤT PHÁT SINH TỪ NHỮNG VẤN ĐỀ TRÊN.
          </p>
        </div>
        <h1 className="title">QUYỀN SỞ HỮU</h1>
        <div className="text">
          <p>
            Ứng dụng và Nền tảng công nghệ của chúng tôi được bảo vệ bởi các quy định về bản quyền, nhãn hiệu và các
            luật khác của Hợp chủng quốc Hoa Kỳ và các nước khác. Trừ khi có quy định khác rõ ràng trong các Điều khoản
            này, chúng tôi (hoặc người cấp phép của chúng tôi) độc quyền sở hữu tất cả các quyền, danh hiệu, và lợi ích
            trong và đối với Ứng dụng và Nền tảng công nghệ, bao gồm tất cả các quyền sở hữu trí tuệ liên quan. Bạn
            không được gỡ bỏ, thay đổi, hoặc làm mờ bất kỳ bản quyền, nhãn hiệu, nhãn dịch vụ hoặc thông báo quyền sở
            hữu khác được tích hợp trong hoặc kèm theo Ứng dụng hoặc Nền tảng công nghệ, bao gồm cả trong bất kỳ nội
            dung nào. Bạn nhận thức đầy đủ và đồng ý rằng bất kỳ phản hồi, bình luận hoặc gợi ý bạn có thể cung cấp liên
            quan đến Ứng dụng và Nền tảng công nghệ (“Phản hồi”) sẽ là tài sản duy nhất và riêng có của Bluecare và bạn,
            tại Thỏa thuận này, đồng ý không hủy ngang chuyển giao cho chúng tôi tất cả các quyền, tên gọi, lợi ích
            trong và liên quan tới tất cả các phản hồi của bạn.
          </p>
        </div>
        <h1 className="title">CHẤM DỨT THỎA THUẬN</h1>
        <div className="text">
          <p>
            Thỏa thuận này có hiệu lực cho tới thời điểm bị chấm dứt bởi Bạn hoặc Bluecare. Nếu bạn vi phạm bất kỳ quy
            định nào của các Điều khoản này, chúng tôi có quyền đình chỉ hoặc vô hiệu hóa sự truy cập hoặc sử dụng của
            bạn đối với Ứng dụng và/hoặc Nền tảng công nghệ. Bạn có thể liên hệ với chúng tôi để hủy bỏ việc sử dụng Ứng
            dụng hoặc Nền tảng công nghệ. Các quyền của bạn theo Thỏa thuận này sẽ tự động chấm dứt mà không cần thông
            báo từ Bluecare nếu bạn không tuân thủ bất kỳ Điều khoản nào của Thỏa thuận này. Khi chấm dứt Thỏa thuận
            này, bạn sẽ chấm dứt tất cả việc sử dụng Nền tảng công nghệ, và gỡ bỏ, tất cả hoặc một phần, các bản cài
            đặt/tải về của Nền tảng công nghệ.
          </p>
        </div>
        <h1 className="title">KHUYẾN CÁO</h1>
        <div className="text">
          <p>
            Bạn hiểu và đồng ý rằng Ứng dụng và Nền tảng công nghệ được cung cấp cho bạn “như hiện trạng” và trên cơ sở
            “như sẵn có”. Không giới hạn bởi các điều kể trên, chúng tôi, từ chối một cách rõ ràng bất cứ bảo hành nào
            về tính tiêu thụ được của hàng hóa, về sự phù hợp của hàng hóa với một mục đích nhất định, sự hưởng thụ thầm
            lặng, hoặc không vi phạm bản quyền và bất cứ bảo hành nào phát sinh trong quá trình làm thương mại hoặc sử
            dụng thương mại. Chúng tôi không đảm bảo rằng Ứng dụng hoặc Nền tảng công nghệ sẽ đáp ứng các yêu cầu của
            bạn hoặc luôn sẵn có trên cơ sở hoạt động không bị gián đoạn, an toàn và không bị lỗi.
          </p>
        </div>
        <h1 className="title">ĐIỀU KHOẢN CHUNG</h1>
        <div className="text">
          <p>
            Hợp đồng chịu điều chỉnh bởi pháp luật Việt Nam, bất kể việc lựa chọn hay các xung đột quy định pháp luật
            của bất kỳ quyền tài phán nào, và bất kỳ tranh chấp, kiện tụng, khiếu nại hay vụ kiện nào phát sinh từ hay
            liên quan đến Các Điều khoản và Điều kiện hoặc Dịch vụ sẽ thuộc quyền xét xử độc quyền của tòa án Việt Nam
            mà bạn bằng Hợp đồng này đồng ý tuân theo.
          </p>
          <p>
            Trong trường hợp luật pháp của Quốc gia Khác không chấp nhận quyền xét xử của tòa án Việt Nam hoặc trường
            hợp phán quyết của tòa án Việt Nam không thể được thi hành tại Quốc gia Khác, các tranh chấp chưa được giải
            quyết sẽ được đệ trình lên Trung tâm Trọng tài Quốc tế Singapore (“SIAC”) để giải quyết, phù hợp với Các Quy
            tắc Tố tụng Trọng tài của SIAC được điều chỉnh hoặc sửa đổi, tại từng thời điểm (“Các Quy tắc Tố tụng Trọng
            tài”), bởi một trọng tài viên duy nhất do Các Bên cùng thống nhất chỉ định (“Trọng tài viên”). Nếu Các Bên
            không thể thống nhất để chọn được một trọng tài viên, Chủ tịch của SIAC sẽ chỉ định Trọng tài viên theo Các
            Quy tắc Tố tụng Trọng tài. Địa điểm giải quyết tranh chấp là Singapore, ngôn ngữ trọng tài bằng tiếng Anh và
            phí Trọng tài sẽ được chia đều cho Các Bên, với điều kiện là Trọng tài viên có thể yêu cầu khoản phí này
            được chi trả theo cách khác mà Trọng tài viên xác định là cần thiết để điều khoản trọng tài này có thể được
            thi hành theo pháp luật áp dụng.
          </p>
          <p>
            <b>
              Không có bất kỳ sự liên doanh, hợp danh, quan hệ lao động, hay quan hệ đại lý nào tồn tại giữa bạn, Công
              ty hay bất kỳ bên cung cấp bên thứ ba nào được tạo thành từ Các Điều khoản và Điều kiện này hay từ việc sử
              dụng Dịch vụ.{" "}
            </b>
            Nếu bất kỳ điều khoản nào của Các Điều khoản và Điều kiện bị coi là không hợp lệ hoặc không thể thi hành,
            thì điều khoản đó sẽ bị xóa bỏ và các điều khoản còn lại sẽ được thi hành trong phạm vi mức tối đa theo luật
            pháp. Việc này cũng áp dụng, không giới hạn, đối với các pháp luật áp dụng và quyền tài phán như nêu trên.
          </p>
          <p>
            Việc Công ty không thể thi hành bất kỳ quyền hay điều khoản nào của Các Điều khoản và Điều kiện không cấu
            thành sự từ bỏ quyền hay điều khoản đó trừ khi được Công ty thừa nhận và chấp thuận dưới dạng văn bản. Các
            Điều khoản và Điều kiện bao gồm toàn bộ thỏa thuận giữa bạn và Công ty và thay thế tất cả các đàm phán hoặc
            thảo luận trước đây, bằng văn bản hoặc bằng lời nói (nếu có) giữa các bên liên quan đến các vấn đề trong tài
            liệu này.
          </p>
          <p>
            Bạn bằng Hợp đồng này đồng ý rằng Công ty có quyền chấm dứt Hợp đồng này tại bất kỳ thời điểm nào bằng cách
            gửi thông báo trước ba (3) ngày cho bạn mà không cần đưa ra bất kỳ lý do nào. Bất kể những nội dung quy định
            trong Hợp đồng này hoặc bất kỳ quy định nào trái ngược, Công ty có quyền chấm dứt Hợp đồng này ngay lập tức
            trong trường hợp bạn bị phát hiện đã vi phạm bất kỳ điều khoản nào nêu trong Hợp đồng này. Để tránh hiểu
            nhầm, việc chấm dứt Hợp đồng này không đòi hỏi Công ty phải bồi thường, bồi hoàn hoặc chi trả bất kỳ phí tổn
            nào mà bạn phải chịu, bao gồm nhưng không giới hạn khoản tiền mà Công ty giữ lại hoặc bất kỳ khoản tiền nào
            đã nộp cho Công ty trong quá trình thực hiện nghĩa vụ của bạn theo Hợp đồng này.
          </p>
        </div>
      </div>
    </div>
  );
};
