import { FormAddBank, RegServiceForm } from "../../types/common";
import axiosClient from "../axiosClient";

export const partnerApi = {
  async getInforPartner() {
    const url = "/tasker/tasker";
    return axiosClient.get(url);
  },

  async getCategoriesPartner() {
    const url = "/tasker/tasker_home";
    return axiosClient.get(url);
  },

  async updateInfoPartner(data: any) {
    const url = "tasker/update-tasker";
    return axiosClient.post(url, data.data, { headers: { "Content-Type": "multipart/form-data" } });
  },

  async getRevenueStatisticsPartner() {
    const url = "tasker/revenue-statistics";
    return axiosClient.get(url);
  },

  async getSalaryDataPartner() {
    const url = "tasker/salary-data";
    return axiosClient.get(url);
  },

  async addBankPartner(data: FormAddBank) {
    const url = "tasker/add-bank-account";
    return axiosClient.post(url, data);
  },

  async getTitlePartner() {
    const url = "tasker/tasker-title";
    return axiosClient.get(url);
  },

  async regBecomToPartner(data: any) {
    const url = "tasker/become_tasker";
    return axiosClient.post(url, data);
  },

  async regServicePartner(data: RegServiceForm) {
    const url = "tasker/reg_services";
    return axiosClient.post(url, data);
  },

  async getAllCategoriesToRegServicePartner() {
    const url = "/tasker/reg_categories";
    return axiosClient.get(url);
  },

  async getScheduleByCategoryPartner(data: { category_id: number; limit: number; client: string }) {
    const url = "/tasker/list_schedules";
    return axiosClient.post(url, data);
  },

  async startJob(data: { id: string }) {
    const url = "/tasker/start_job";
    return axiosClient.post(url, data);
  },

  async finishJob(data: { id: string }) {
    const url = "/tasker/finish_job";
    return axiosClient.post(url, data);
  },

  async takeJob(data: { client: string; ids: Array<number> }) {
    const url = "/tasker/take_job";
    return axiosClient.post(url, data);
  },

  async getAllSchedulePartner() {
    const url = "/tasker/my_schedule";
    return axiosClient.get(url);
  },

  async rateUser(data: any) {
    const url = "/tasker/rate_user";
    return axiosClient.post(url, data);
  },
};
