import { useLocation, useNavigate } from "react-router-dom";
import HeaderService from "../../../components/HeaderService";
import { notification } from "antd";
import { useEffect, useState } from "react";
import overLoader from "../../../components/overLoader";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";
import paths from "../../../config";

export const TransferInfo = () => {
  const [statusLoader, setStatusLoader] = useState<boolean>(true);
  const [api, showPopup] = notification.useNotification();
  const navigate = useNavigate();
  const { state } = useLocation();
  const html = state as string;
  const { t, i18n } = useTranslation(["booking"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 2,
    });
  };
  const handleAddQRCode = () => {
    const bankInfo = document.querySelectorAll(".bank-info-box");
    const listBank: string[] = [];
    const listBankInfo: {
      bankName: string;
      owner: string;
      accountNumber: string;
      amount: string;
      content: string;
    }[] = [];
    bankInfo.forEach((item) => {
      const bankName = item.querySelector(".payment-title")?.textContent?.split(" - ")[1];
      const owner = item.querySelectorAll("p")[1]?.textContent?.split(": ")[1];
      const accountNumber = item.querySelectorAll("p")[2]?.textContent?.split(": ")[1];
      const amount = item.querySelectorAll("p")[4]?.textContent?.split(": ")[1].replace("đ", "").replace(".", "");
      const content = item.querySelectorAll("p")[5]?.textContent?.split(": ")[1];
      if (bankName && owner && accountNumber && amount && content) {
        listBank.push(bankName) &&
          listBankInfo.push({
            bankName,
            owner,
            accountNumber,
            amount,
            content,
          });
      }
    });
    listBankInfo.forEach((item, index) => {
      const existingQRCode = bankInfo[index].querySelector(".qr-code");
      if (existingQRCode) {
        bankInfo[index].removeChild(existingQRCode);
      }
      const qrCodeParent = document.createElement("div");
      qrCodeParent.className = "qr-code";
      const qrCode = document.createElement("img");
      qrCode.src = `https://img.vietqr.io/image/${item.bankName}-${item.accountNumber}-compact2.png?amount=${item.amount}&addInfo=${item.content}&accountName=${item.owner}`;
      qrCode.alt = "QR code";
      qrCodeParent.appendChild(qrCode);
      bankInfo[index].appendChild(qrCodeParent);
    });
    const buttonSuccess = document.querySelector(".btn.btn-success");
    const parentButtonSuccess = buttonSuccess?.parentNode;
    if (parentButtonSuccess) {
      parentButtonSuccess.removeChild(buttonSuccess);
      const button = document.createElement("button");
      button.className = "confirm-button btn btn-success";
      button.textContent = t("xac_nhan");
      button.onclick = () => {
        alertSuccess(t("cam_on_ban_da_dat_dich_vu"));
        setTimeout(() => {
          //   navigate("/user/home");
          navigate(paths.homeUser);
        }, 2000);
      };
      parentButtonSuccess.appendChild(button);
    }
  };

  setTimeout(() => {
    handleAddQRCode();
    setStatusLoader(false);
  }, 3000);

  return (
    <div className="wrapper transfer-info">
      {showPopup}
      {statusLoader && overLoader()}
      <HeaderService text={t("thong_tin_chuyen_khoan")} />
      <div className="transfer-info content-container">
        <div dangerouslySetInnerHTML={{ __html: html }} className="transfer-info-content" />
      </div>
    </div>
  );
};
