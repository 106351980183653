import axiosClient from "../axiosClient";

export const authApi = {
  async login(data: any) {
    const url = `/auth/login`;
    return axiosClient.post(url, data);
  },

  async loginFacebook(data: { access_token: string; fb_id: number }) {
    const url = `auth/authentication-facebook`;
    return axiosClient.post(url, data);
  },

  async loginGoogle(data: { access_token: string }) {
    const url = `auth/authentication-google`;
    return axiosClient.post(url, data);
  },

  async loginApple(data: { code: string; name: string; email: string; role: string }) {
    const url = `auth/authentication-apple/web`;
    return axiosClient.post(url, data);
  },

  async logout() {
    const url = "auth/logout";
    return axiosClient.get(url);
  },

  async register(data: any) {
    const url = "auth/register";
    return axiosClient.post(url, data);
  },

  async resetPassword(data: any) {
    const url = "auth/reset-password";
    return axiosClient.post(url, data);
  },

  async sendOTP() {
    const url = "auth/send-sms-otp";
    return axiosClient.get(url);
  },

  async verifyOTP(data: any) {
    const url = "auth/verify-otp";
    return axiosClient.post(url, data);
  },

  // partner
  async loginPartner(data: any) {
    const url = `/tasker/login`;
    return axiosClient.post(url, data);
  },

  async loginPartnerFacebook(data: { access_token: string; fb_id: number }) {
    const url = `tasker/authentication-facebook`;
    return axiosClient.post(url, data);
  },

  async loginPartnerGoogle(data: { access_token: string }) {
    const url = `tasker/authentication-google`;
    return axiosClient.post(url, data);
  },
  async registerPartner(data: any) {
    const url = `tasker/register`;
    return axiosClient.post(url, data);
  },
};
