import { useEffect } from "react";
import HeaderSevice from "../../../components/HeaderService";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getVoucherByIdUser } from "../../../redux/Slices/userSlice";
import { getPathByIndex } from "../../../utils/func";
import LoadingParagraph from "../../../components/skeleton";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";

export const PromotionDetail = () => {
  const dispatch = useAppDispatch();
  const vourcherDetailData = useAppSelector((state) => state.user.voucherDetail);
  const loadingVourcherDetail = useAppSelector((state) => state.user.loadingVourcherDetail);
  const vourchId = getPathByIndex(2) ?? "";
  const { t, i18n } = useTranslation(["home"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);
  useEffect(() => {
    dispatch(getVoucherByIdUser(vourchId.toString()));
  }, [dispatch, vourchId]);

  return (
    <div className="wrapper promotion-detail">
      <HeaderSevice text={t("chi_tiet_khuyen_mai")} />

      <div className="promotion-detail content-container">
        {!loadingVourcherDetail ? (
          <div className="promotion-detail-text" dangerouslySetInnerHTML={{ __html: vourcherDetailData?.rawdata }} />
        ) : (
          <LoadingParagraph />
        )}
      </div>
    </div>
  );
};
