export const getQueryParams = (name: string) => {
  return new URLSearchParams(window ? window.location.search : "").get(name);
};

export const getPathByIndex = (index: string | number): string | null => {
  const path = window.location.pathname;
  const parts = path.split("/");

  const idx = typeof index === "string" ? parseInt(index, 10) : index;

  if (idx >= 0 && idx < parts.length) {
    return parts[idx];
  }
  return null;
};

export const formatPrice = (p: number, NOD: boolean = false): string => {
  if (!p) return "0";

  p = Math.round(p);
  let pStr = p.toString();
  let n = 0;
  let tmp = "";
  let rs = pStr[0];

  for (let i = pStr.length - 1; i > 0; i--) {
    n++;
    tmp += pStr[i];
    if (n % 3 === 0) {
      tmp += ".";
    }
  }

  for (let i = tmp.length - 1; i >= 0; i--) {
    rs += tmp[i];
  }

  if (NOD) {
    return rs;
  }

  return rs + "₫";
};

export const removeAccents = (str: string) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D");
};
